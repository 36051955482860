import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { motion } from 'framer-motion'

import incentivesScreen from '../../../assets/images/how-it-works/incentives-screen.jpg'
import incentivesBalance from '../../../assets/images/how-it-works/incentives-balance.svg'
import incentivesAmounts from '../../../assets/images/how-it-works/incentives-amounts.svg'
import incentivesActions from '../../../assets/images/how-it-works/incentives-actions.svg'
import Animations from '../../../utils/animations'
import Paths from '../../../utils/paths'
import useStyles from '../HowItWorks.styles'

export default function HowItWorksIncentives () {
  const classes = useStyles()
  const navigation = useNavigate()

  return (
    <div className={classes.incentivesContainer}>
      <div className={classes.incentivesTitleContainer}>
        <h1 className={classes.incentivesTitle}>Unlock unmatched incentives </h1>
        <h2 className={classes.incentivesSubtitle}>Build your workforce while boosting employee confidence and financial resilience.</h2>
        <Button
          variant='outlined'
          className={classes.incentivesButton}
          onClick={() => navigation(Paths.CONTACT)}
        >
          Get in touch
        </Button>
      </div>
      <div style={{flex: 0.6, position: 'relative'}}>
        <div className={classes.incentivesPopContainer}>
          <div style={{flex: 1}}>
            <motion.div
              {...Animations.POP({ duration: 0.8, delay: 0.8 })}
            >
              <img src={incentivesBalance} alt='Account Balance' className={classes.incentivesPopImage} />
            </motion.div>
          </div>
          <div style={{flex: 1}}>
            <motion.div
              {...Animations.POP({ duration: 0.8, delay: 1 })}
              >
              <img src={incentivesAmounts} alt='Amounts' className={classes.incentivesPopImage} />
            </motion.div>
            <motion.div
              {...Animations.POP({ duration: 0.8, delay: 0.8 })}
              >
              <img src={incentivesActions} alt='Actions' className={classes.incentivesPopImage} />
            </motion.div>
          </div>
        </div>
        <motion.div className={classes.incentivesImageContainer}
          {...Animations.POP({ duration: 0.5})}
          >
          <img src={incentivesScreen} className={classes.incentivesImage} alt='MK Card' />
        </motion.div>
      </div>
    </div>
  )
}