import { Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import Animations from '../../utils/animations'
import Paths from '../../utils/paths'
import useStyles from './SlideHeader.styles'

export default function SlideHeader ({
  backgroundClass,
  buttonText,
  figure,
  figureClass,
  image,
  path,
  textPosition,
  title,
  text
}) {
  const navigate = useNavigate()
  const classes = useStyles()

  return (
    <div className={'d-flex row pt-1 pb-5 pb-md-1 header-container ' + backgroundClass}>
      {figure !== undefined
        ? (
          <motion.img src={figure} alt='figure' className={figureClass} />
          )
        : (
            ''
          )}
      <div
        className={`col-12 px-0 px-sm-5 pb-4 d-inline d-md-none ${
          textPosition === 'left' ? 'order-1 order-md-2' : 'order-1'
        } `}
      >
        <motion.img src={image} alt='img' className='img-header' {...Animations.FADE()} />
      </div>
      <div
        className={`col-12 col-md-6 d-flex align-items-center p-1 p-md-5 d-flex ${
          textPosition === 'left' ? 'order-1' : 'order-2 order-md-2'
        } `}
      >
        <div className='d-flex flex-column'>
          <div
            className={`header-text-container ${textPosition === 'left' ? '' : 'ps-md-0'} `}
          >
            <h1 className='web-header d-flex pb-4'>{title}</h1>
            <div className='largue-text pb-3'>{text}</div>
            <div className='d-flex justify-content-start'>
              <Button
                variant='outlined'
                className={classes.buttonBlack}
                onClick={() => navigate(path ?? Paths.CONTACT)}
              >
                {buttonText ?? 'Get a demo'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`col-6 d-none d-md-inline ${
          textPosition === 'left' ? 'order-1 order-md-2' : 'order-1 p-5'
        } `}
        style={{ position: 'relative' }}
      >
        <motion.img src={image} alt='img' className='img-header' style={{ maxWidth: '80%' }} {...Animations.FADE()} />
      </div>
    </div>
  )
}
