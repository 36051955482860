import React from 'react'

import SlideCarousel from '../../components/SlideCarousel'
import RedCardBlock from '../../components/red-card-block/RedCardBlock'
import CarouselImg1 from '../../assets/images/carrousel/benefits-1.png'
import CarouselImg2 from '../../assets/images/carrousel/benefits-2.png'
import CarouselImg3 from '../../assets/images/carrousel/benefits-3.png'
import { BenefitsAccordions, BenefitsHeader, BenefitsServices, BenefitsSlides } from './components'

export default function Benefits () {

  return (
    <div>
      <BenefitsHeader />
      <BenefitsAccordions />
      <BenefitsServices />
      <BenefitsSlides  />
      <SlideCarousel items={carouselItems} /> 
      <RedCardBlock title='See how MK can help your company' buttonText='Get a demo' />
    </div>
  )
}

const carouselItems = [
  {
    title: 'How a white-label platform helped our company.',
    text: 'We have a white-label platform that our employees love and use very often. We worked directly with Multikrd to design the look and feel that aligned with our expectations, and they did not fail to deliver! ',
    autor: '- Charlotte M.,CHRO',
    header: 'USER STORY:',
    img: CarouselImg1,
    design: 2
  },
  {
    title: '“This app gives me a cash float and helps me stay organized…”',
    text: 'I was never able to disburse tips to my staff so easily. I used to go to the bank almost daily, but not anymore. This app gives me a cash float and helps me stay organized with the tips and wages that my staff receives each day. We all love it!',
    autor: '- Nick S., Co-Founder and Owner',
    img: CarouselImg2,
    design: 1
  },
  {
    title: '“The onboarding process was seamless…”',
    text: 'The onboarding process was seamless and we were always provided with support. Multikrd has helped us forget about paper checks and given us the opportunity to use their cards to deposit wages to our personnel in a very simple manner.',
    autor: '- Trina F., HR Director',
    img: CarouselImg3,
    design: 3
  }
]
