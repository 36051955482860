import React, { useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Button } from '@material-ui/core'
import { motion } from 'framer-motion'

import DialogVideo from '../../../components/DIalogVideo'
import Animations from '../../../utils/animations'
import { MediaItem } from './index'

export default function MediaVideos () {
  const [open, setOpen] = useState(false)
  const [selectVideo, setSelectVideo] = useState(0)

  return (
    <>
      <DialogVideo open={open} setOpen={setOpen} element={videos[selectVideo]} />
      <div className='row p-5'>
        <div className='web-header'>Videos</div>
      </div>
      <Carousel indicators={false} className='w-100 carrousel-video'>
        {videos.map((item, i) => (
          <MediaItem
            key={`carrousel-${i}`}
            item={item}
            open={open}
            setVideo={setSelectVideo}
            setOpen={setOpen}
            index={i}
          />
        ))}
      </Carousel>
      <div className='row'>
        {videos.map(({image, title, date, description}, index) => {
          const delay = index % 2 === 0 ? 0.3 : 0

          return (
            <div
              key='video-{index}'
              className='col-12 col-sm-6 col-md-4 p-4'
              style={{ textAlign: 'left' }}
            >
              <div>
                <Button
                  onClick={() => {
                    setOpen(true)
                    setSelectVideo(index)
                  }}
                >
                  <motion.img src={image} alt='video-img' className='media-header-img' {...Animations.POP({ delay })} />
                </Button>
              </div>
              <div className='medium pt-4 text-start'>{title}</div>
              <div className='text-regular pb-4'>{date}</div>
              <div className='text-regular pb-5'>
                {description}
                <Button
                  onClick={() => {
                    setOpen(true)
                    setSelectVideo(index)
                  }}
                  color='primary'
                  className='more-button'
                >
                  + more
                </Button>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

const videos = [
  {
    title: 'Introducing the Multikrd Financial Wellness membership',
    description:
      'Multikrd is a zero-cost solution designed to boost your employees’ financial health and motivation. This is the only platform that integrates wage access, builds cardowner credit, offers cashback savings and members- only discounts at over 1.5 million affiliated merchants. Be a business where good things happen all the time! Multikrd enriches and rewards employees who join your team, contribute fully, and stick around: Remember that motivated employees are happier, more engaged, and more productive.',
    url: 'https://youtu.be/MIcIFSMMnus',
    image: 'https://img.youtube.com/vi/MIcIFSMMnus/sddefault.jpg'
  },
  {
    title: 'Activate your Multikrd membership!',
    description:
      'Welcome to your Multikrd Wellness Membership! Discover the one of a kind program that rewards you for being a valued employee and gives you access to special promotions not available to the open public. Make your life easier through Earned Wage Access, Real Cashback to your account, thousands of promotions at over 700,000 retailers and 6,000 online stores, Travel, Adventures and much more!',
    url: 'https://youtu.be/MIcIFSMMnus',
    image: 'https://img.youtube.com/vi/MIcIFSMMnus/sddefault.jpg'
  },
  {
    title: 'Unlock your Earned Wages Access with the MK membership',
    description:
      "Multikrd's Easy Banking service gives you simple and fast access to a bank account and a secured credit card.In this video you will learn about the simple steps it takes to get yours!",
    url: 'https://youtu.be/wTtYLhOxw18',
    image: 'https://img.youtube.com/vi/wTtYLhOxw18/sddefault.jpg'
  },
  {
    title: 'Be a smar shopper and experience more',
    description:
      'Your MK Financial Wellness Membership is the best way to stretch your budget, thanks to the exclusive tools it offers. Take advantage of the greatest cashback program, the biggest discount network of affiliated merchants, and experience the adventures you have dreamed of, all at the reach of your hand. Shop smart, save and experience more!',
    url: 'https://youtu.be/1cVcQYkL5G8',
    image: 'https://img.youtube.com/vi/1cVcQYkL5G8/sddefault.jpg'
  }
]
