import React from 'react'
import { motion } from 'framer-motion'

import image from '../../../assets/images/discounts/categories-image.webp'
import education from '../../../assets/images/discounts/education.svg'
import events from '../../../assets/images/discounts/events.svg'
import food from '../../../assets/images/discounts/food.svg'
import health from '../../../assets/images/discounts/health.svg'
import shopping from '../../../assets/images/discounts/shopping.svg'
import Footnote from '../../../components/footnote/Footnote'
import useStyles from '../Discounts.styles'
import Animations from '../../../utils/animations'

export default function DiscountsCategories () {
  const classes = useStyles()

  return (
    <div className={classes.categoriesContainer}>
      <div className={classes.categoriesIconsContainer}>
        <motion.div className={classes.categoriesIcon} {...Animations.POP({ delay: 0.3 })}>
          <img src={health} className={classes.categoriesIconImage} alt='Health' />
          <h2 style={{ fontWeight: 'bold', color: '#0097EC' }}>Health & Wellness</h2>
        </motion.div>
        <motion.div className={classes.categoriesIcon} {...Animations.POP({ delay:  0.6 })}>
          <img src={food} className={classes.categoriesIconImage} alt='Food' />
          <h2 style={{ fontWeight: 'bold', color: '#F1645D' }}>Food & Dining</h2>
        </motion.div>
        <motion.div className={classes.categoriesIcon} {...Animations.POP({ delay: 0.9 })}>
          <img src={events} className={classes.categoriesIconImage} alt='Events' />
          <h2 style={{ fontWeight: 'bold', color: '#FDBA63' }}>Events & Attractions</h2>
        </motion.div>
        <motion.div className={classes.categoriesIcon}  {...Animations.POP({ delay: 1.2 })}>
          <img src={shopping} className={classes.categoriesIconImage} alt='Shopping' />
          <h2 style={{ fontWeight: 'bold', color: '#C1D82F' }}>Shopping</h2>
        </motion.div>
        <motion.div className={classes.categoriesIcon}  {...Animations.POP({ delay: 1.5 })}>
          <img src={education} className={classes.categoriesIconImage} alt='Education' />
          <h2 style={{ fontWeight: 'bold', color: '#73C16C' }}>Education</h2>
        </motion.div>
        <div className={classes.categoriesDisclaimer}>
          <Footnote notes={['(1) Subject to Terms & Conditions.']} />
        </div>
      </div>
      <div className={classes.categoriesTitleContainer}>
        <h1 className={classes.categoriesTitle}>Over 1.5 Million Offers⁽¹⁾</h1>
        <h2 className={classes.categoriesSubtitle}>Explore exclusive discounts, coupons, special offers, and promo codes.</h2>
        <h2 className={classes.categoriesSubtitle}>Whether you're shopping online or in-person, Multikrd connects you with savings on everything from daily essentials to special purchases.</h2>
        <img src={image} className={classes.categoriesImage} alt='MK Card' />
      </div>
    </div>
  )
}