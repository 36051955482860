import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  agreementsButton: {
    color: '#CFEEFA',
    fontFamily: 'Work Sans',
    fontSize: 18,
    fontWeight: 'bold',
    justifyContent: 'center',
    textDecoration: 'none',
    textTransform: 'none'
  },
  appsContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center'
    }
  },
  appsImage: { 
    height: 32
  },
  appsTitle: { 
    color: '#0097EC', 
    fontFamily: 'Work Sans', 
    fontSize: 20, 
    fontWeight: 'bold', 
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    }
  },
  button: {
    textTransform: 'none'
  },
  container: {
    backgroundColor: '#1E4164',
    paddingBottom: 40,
    paddingTop: 40,
    width: '100%'
  },
  logo: {
    height: 48
  },
  navigationButton: {
    color: 'white',
    fontFamily: 'Work Sans',
    fontSize: 18,
    justifyContent: 'start',
    letterSpacing: -0.1,
    lineHeight: '140%',
    marginBottom: 4,
    padding: 0,
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      textAlign: 'left'
    }
  },
  navigationContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%'
  },
  socialTitle: {
    color: '#0097EC', 
    fontFamily: 'Work Sans', 
    fontSize: 24, 
    fontWeight: 'bold', 
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    }
  }
}), { classNamePrefix: 'Footer' })