import React from 'react'
import { useNavigate } from 'react-router-dom'

import image from '../../../assets/images/discounts/header.webp'
import Paths from '../../../utils/paths'
import useStyles from '../Discounts.styles'

export default function CardHeader () {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.headerContainer} onClick={() => navigate(Paths.CONTACT)}>
      <img src={image} alt='header-image' className={classes.headerImage} />
    </div>
  )
}