import React, { useState } from 'react'
import { Checkbox, MenuItem, TextField, makeStyles, Button, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { postSignup } from '../services/signup'
import { TwoFactorModal } from '../components/TwoFactorModal'
import { USA_STATES } from '../utils/constants'

const useStyles = makeStyles(theme => ({
  buttonSecondaryBlack: {
    textTransform: 'none',
    backgroundColor: 'black !important',
    color: 'white !important',
    borderRadius: 30,
    paddingInline: 25,
    paddingTop: 10,
    paddingBottom: 10,
    width: '15rem'
  },
  input: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      paddingRight: '24px !important',
      transform: 'translate(14px, 24px) scale(1) !important'
    }
  }
}))

const DefaultUser = {
  firstName: '',
  lastName: '',
  email: '',
  mobilePhone: '',
  streetAddress: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  password: '',
  birthdate: '',
  termsConditions: false,
  promotionalSms: false,
  promotionalPhoneCalls: false,
  promotionalEmail: false
}

const validateBirthdate = dateString => {
  const today = new Date()
  const birthDate = new Date(dateString)
  const age = today.getFullYear() - birthDate.getFullYear()
  const monthDiff = today.getMonth() - birthDate.getMonth()
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1
  }
  return age
}

export default function SignupForm (props) {
  const classes = useStyles()

  const [state, setState] = useState({ ...DefaultUser })
  const [errors, setErrors] = useState({ ...DefaultUser })
  const [alertOpen, setOpenAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [twoFactorError, setTwoFactorError] = useState(false)
  const [openTwoFactor, setOpenTwoFactor] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenAlert(false)
  }

  const getErrorMessage = error => {
    console.log(error)
    if (error?.tag === 'INVALID_CODE') {
      return 'The sms code is not valid.'
    } else if (error?.tag === 'MAX_ATTEMPTS_REACHED') {
      return 'Second factor auth. max. attempts reached.'
    } else if (error?.tag === 'EMAIL_HASH_ALREADY_EXISTING') {
      return 'This email is already registered.'
    } else if (error?.tag === 'MALFORMED_REQUEST') {
      console.log('MALFORMED_REQUEST')
      return error?.message
    } else {
      return 'Something happened, please try again later.'
    }
  }

  const handleTwoFactorSubmit = code => {
    setIsLoading(true)
    postSignup({ user: state, code })
      .then(res => {
        if (res.success === false) {
          setOpenTwoFactor(false)
          setErrorMessage(getErrorMessage(res.error?.response?.data))
          setOpenAlert(true)
        } else {
          setOpenTwoFactor(false)
          setErrorMessage('')
          setOpenAlert(true)
          setState({ ...DefaultUser })
          // navigate('/customers')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handlesSubmit = () => {
    setOpenAlert(false)
    setErrorMessage('')
    const requiredFieldMessage = 'This field is required'
    let error = { ...DefaultUser }
    let errorFlag = false
    if (!state.firstName) {
      error = { ...error, firstName: requiredFieldMessage }
      errorFlag = true
    }
    if (!state.lastName) {
      error = { ...error, lastName: requiredFieldMessage }
      errorFlag = true
    }
    if (!state.email) {
      error = { ...error, email: requiredFieldMessage }
      errorFlag = true
    }
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        state.email
      )
    ) {
      error = { ...error, email: 'Invalid email format' }
      errorFlag = true
    }
    if (!state.password) {
      error = { ...error, password: requiredFieldMessage }
      errorFlag = true
    }
    if (!state.birthdate) {
      error = { ...error, birthdate: requiredFieldMessage }
      errorFlag = true
    } else {
      const age = validateBirthdate(state.birthdate)
      if (age < 18) {
        error = { ...error, birthdate: 'Must be 18 years or older' }
        errorFlag = true
      }
    }
    if (!state.mobilePhone) {
      error = { ...error, mobilePhone: requiredFieldMessage }
      errorFlag = true
    }
    if (!state.streetAddress) {
      error = { ...error, streetAddress: requiredFieldMessage }
      errorFlag = true
    }
    if (!state.state) {
      error = { ...error, state: requiredFieldMessage }
      errorFlag = true
    }
    if (!state.city) {
      error = { ...error, city: requiredFieldMessage }
      errorFlag = true
    }
    if (!state.zipCode) {
      error = { ...error, zipCode: requiredFieldMessage }
      errorFlag = true
    }
    if (!state.termsConditions) {
      error = { ...error, termsConditions: requiredFieldMessage }
      errorFlag = true
    }
    setErrors(error)
    if (!errorFlag) {
      setOpenTwoFactor(true)
    }
  }

  return (
    <>
      <div className='pb-4'>
        <TextField
          className={classes.input}
          label='First name'
          variant='outlined'
          fullWidth
          value={state.firstName}
          onChange={e => {
            setState({ ...state, firstName: e.target.value })
          }}
          error={errors.firstName !== ''}
          helperText={errors.firstName}
        />
      </div>
      <div className='pb-4'>
        <TextField
          className={classes.input}
          label='Last name'
          variant='outlined'
          fullWidth
          value={state.lastName}
          onChange={e => {
            setState({ ...state, lastName: e.target.value })
          }}
          error={errors.lastName !== ''}
          helperText={errors.lastName}
        />
      </div>
      <div className='pb-4'>
        <TextField
          className={classes.input}
          label='Email'
          variant='outlined'
          fullWidth
          value={state.email}
          onChange={e => {
            setState({ ...state, email: e.target.value })
          }}
          error={errors.email !== ''}
          helperText={errors.email}
        />
      </div>
      <div className='pb-4'>
        <TextField
          className={classes.input}
          label='Password'
          type='password'
          variant='outlined'
          fullWidth
          value={state.password}
          onChange={e => {
            setState({ ...state, password: e.target.value })
          }}
          error={errors.password !== ''}
          helperText={errors.password}
        />
      </div>
      <div className='pb-4'>
        <TextField
          className={classes.input}
          label='Birthdate'
          type='date'
          variant='outlined'
          fullWidth
          value={state.birthdate}
          onChange={e => {
            setState({ ...state, birthdate: e.target.value })
          }}
          error={errors.birthdate !== ''}
          helperText={errors.birthdate}
        />
      </div>
      <div className='pb-4'>
        <TextField
          className={classes.input}
          label='Phone'
          variant='outlined'
          fullWidth
          value={state.mobilePhone}
          onChange={e => {
            setState({ ...state, mobilePhone: e.target.value })
          }}
          error={errors.mobilePhone !== ''}
          helperText={errors.mobilePhone}
        />
      </div>
      <div className='pb-4'>
        <TextField
          className={classes.input}
          label='Address 1'
          variant='outlined'
          fullWidth
          value={state.streetAddress}
          onChange={e => {
            setState({ ...state, streetAddress: e.target.value })
          }}
          error={errors.streetAddress !== ''}
          helperText={errors.streetAddress}
        />
      </div>
      <div className='pb-4'>
        <TextField
          className={classes.input}
          label='Address 2'
          variant='outlined'
          fullWidth
          value={state.addressLine2}
          onChange={e => {
            setState({ ...state, addressLine2: e.target.value })
          }}
          error={errors.addressLine2 !== ''}
          helperText={errors.addressLine2}
        />
      </div>
      <div className='pb-4'>
        <TextField
          className={classes.input}
          label='State'
          select
          variant='outlined'
          fullWidth
          value={state.state}
          onChange={e => {
            setState({ ...state, state: e.target.value })
          }}
          style={{ textAlign: 'left' }}
          error={errors.state !== ''}
          helperText={errors.state}
        >
          {USA_STATES.map(state => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className='pb-4'>
        <TextField
          className={classes.input}
          label='City'
          variant='outlined'
          fullWidth
          value={state.city}
          onChange={e => {
            setState({ ...state, city: e.target.value })
          }}
          error={errors.city !== ''}
          helperText={errors.city}
        />
      </div>
      <div className='pb-4'>
        <TextField
          className={classes.input}
          label='Zip code'
          variant='outlined'
          fullWidth
          value={state.zipCode}
          onChange={e => {
            setState({ ...state, zipCode: e.target.value })
          }}
          error={errors.zipCode !== ''}
          helperText={errors.zipCode}
        />
      </div>
      <div className='pb-4' style={{ textAlign: 'left' }}>
        <Checkbox
          style={{ color: errors.termsConditions ? 'red' : '#0097EC' }}
          checked={state.termsConditions}
          onChange={() => setState({ ...state, termsConditions: !state.termsConditions })}
        />
        I've read and accepted the{' '}
        <a
          style={{ color: '#0097EC' }}
          href='https://platform.accesswages.com/legal-agreements'
          target='_blank'
          rel='noreferrer'
        >
          Agreements, Terms and Conditions, Terms of Use, Terms of Services and Privacy Policies
        </a>
        .<span style={{ color: 'red', fontSize: '0.75rem' }}>{errors.termsConditions}</span>
      </div>
      <div className='pb-4' style={{ textAlign: 'left' }}>
        Yes! I want to subscribe to the following marketing lists to receive special promotions and
        information about my account
      </div>
      <div className='pb-4' style={{ textAlign: 'left' }}>
        <Checkbox
          style={{ color: '#0097EC' }}
          checked={state.promotionalSms}
          onChange={() => setState({ ...state, promotionalSms: !state.promotionalSms })}
        />
        Promotional SMS
      </div>
      <div className='pb-4' style={{ textAlign: 'left' }}>
        <Checkbox
          style={{ color: '#0097EC' }}
          checked={state.promotionalPhoneCalls}
          onChange={() =>
            setState({ ...state, promotionalPhoneCalls: !state.promotionalPhoneCalls })}
        />
        Promotional phone calls
      </div>
      <div className='pb-4' style={{ textAlign: 'left' }}>
        <Checkbox
          style={{ color: '#0097EC' }}
          checked={state.promotionalEmail}
          onChange={() => setState({ ...state, promotionalEmail: !state.promotionalEmail })}
        />
        Promotional emails
      </div>
      <div className='pb-4 d-flex justify-content-start'>
        <Button variant='outlined' className={classes.buttonSecondaryBlack} onClick={handlesSubmit}>
          Signup now
        </Button>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={errorMessage ? 'error' : 'success'}>
          {errorMessage ||
            'Success! your phone number is now authenticated. Please check your email for a message coming from Multikrd to complete te activation process.'}
        </Alert>
      </Snackbar>
      <TwoFactorModal
        isOpen={openTwoFactor}
        hasError={twoFactorError}
        isLoading={isLoading}
        mobilePhone={state.mobilePhone}
        onClose={() => setOpenTwoFactor(false)}
        onSubmit={handleTwoFactorSubmit}
        onSendAgain={() => {
          console.log('ON SEND AGAIN')
        }}
      />
    </>
  )
}
