import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  blueTitle: {
    color: '#0097EC',
    fontSize: 64,
    fontWeight: 'bold',
    paddingBottom: 56,
    paddingTop: 56,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      paddingBottom: 32,
      paddingTop: 32,
    }
  },
  categoriesContainer: {
    display: 'flex',
    marginBottom: 40,
    paddingBottom: 32,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 32,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16
    }
  },
  categoriesIconsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: 32,
    position: 'relative',
    width: '80%',
    [theme.breakpoints.down('md')]: {
      gap: 16,
    }
  },
  categoriesIcon: {
    alignItems: 'center',
    display: 'flex',
    gap: 32,
    justifyContent: 'flex-start',
    paddingBottom: 32,
    paddingLeft: 56,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      paddingLeft: 32
    }
  },
  categoriesIconImage: {
    height: 80,
    [theme.breakpoints.down('md')]: {
      height: 56
    }
  },
  categoriesImageContainer: {
    display: 'flex'
  },
  categoriesImage: {
    margin: '0 auto',
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '60%'
    }
  },
  categoriesShape: {
    bottom: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: -1
  },
  categoriesSubtitle: {
    fontFamily: 'Work Sans',
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      marginBottom: 16
    }
  },
  categoriesTitle: {
    fontFamily: 'Work Sans',
    fontSize: 56,
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
    }
  },
  categoriesTitleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingBottom: 16,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 16,
    textAlign: 'left'
  },
  exampleContainer: {
    backgroundColor: '#CFEEFA',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 118
    }
  },
  exampleGridContainer: {
    display: 'grid', 
    gridTemplateColumns: '3fr 1fr', 
    gridTemplateRows: '2fr 1fr', 
    gap: 16,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '2fr 1fr', 
      gap: 0
    }
  },
  exampleImagesContainer: {
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row'
    }
  },
  exampleImage: {
    height: 'auto',
    width: '90%', 
    [theme.breakpoints.down('sm')]: {
      width: '16.66%'
    }
  },
  exampleOthersContainer: {
    gridColumn: ' 1/2', 
    gridRow: '1/3', 
    display: 'grid', 
    gridTemplateColumns: '2fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    }
  },
  exampleMultikrdContainer: {
    gridColumn: '2/3', 
    gridRow: '1/3', 
    background: 'rgba(30, 65, 100, 0.95)', 
    borderRadius: 16, 
    color: '#CFEEFA',
    paddingBottom: 40
  },
  examplePlane: {
    position: 'absolute',
    bottom: 0,
    right: '15%',
    [theme.breakpoints.down('md')]: {
      width: '20%',
      bottom: 50  
    },
    [theme.breakpoints.down('xs')]: {
      width: '35%',
      bottom: 100  
    }
  },
  exampleTotalContainer: {
    gridColumn: '1/2', 
    gridRow: '2/3'
  },
  exampleTotal: {
    color: '#004598', 
    paddingTop: 80, 
    fontSize: 40, 
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 24
    }
  },  
  exampleTotalAmount: {
    fontSize: 40, 
    textAlign: 'right', 
    paddingTop: 40, 
    paddingBottom: 56, 
    paddingLeft: 16, 
    paddingRight: 40,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 118,
      paddingLeft: 8,
      paddingRight: 16,
      fontSize: 24
    }
  },  
  exampleText: {
    fontSize: 28,
    textAlign: 'right',
    padding: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      padding: 16,
      paddingTop:24,
      paddingBottom: 24
    }
  },
  exampleServiceText: {
    fontSize: 28,
    textAlign: 'right',
    padding: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      padding: 16,
      paddingTop: 24
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      padding: 16,
      paddingTop: 8
    }
  },
  exampleTextHeader: {
    fontSize: 28, 
    padding: 24,
    height: 100,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      padding: 8,
    }
  },
  exampleMultikrdHeader: {
    fontSize: 28, 
    padding: 24,
    height: 100, 
    backgroundColor: '#1E4164', 
    borderTopLeftRadius: 16, 
    borderTopRightRadius: 16,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      padding: 8,
    }
  },
  exampleMultikrdText: {
    fontSize: 28, 
    textAlign: 'right',
    padding: 24,
    paddingRight: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    }
  },
  exampleTextContainer: { 
    paddingRight: 48, 
    paddingTop: 64,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 32,
      paddingRight: 32,
      paddingTop: 40,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  exampleSubtitle: {
    textAlign: 'right', 
    fontWeight: 'bold', 
    marginTop: 40, 
    marginBottom: 40, 
    color: '#1E4164',
    [theme.breakpoints.down('md')]: {
      marginTop: 24,
      marginBottom: 24, 
    }
  },
  exampleTitle: {
    textAlign: 'right', 
    fontWeight: 'bold', 
    marginTop: 40, 
    marginBottom: 40,
    [theme.breakpoints.down('md')]: {
      marginTop: 24,
      marginBottom: 24, 
    }
  },
  headerContainer: {
    marginBottom: 32,
    position: 'relative'
  },
  headerImage: {
    cursor: 'pointer',
    width: '100%'
  },
  headerNotification: {
    left: 0,
    margin: '0 auto',
    position: 'absolute',
    right: 0,
    top: 56,
    [theme.breakpoints.down('md')]: {
      top: 16,
      width: '40%'
    }
  }
}), { classNamePrefix: 'Travel' })