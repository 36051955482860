import { Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import Animations from '../../utils/animations'
import useStyles from './SlideImage.styles'

export default function SlideImage({ animation, background, image, button, buttonLink, buttonText, secondaryButtonText, secondaryButtonLink, text, textPosition, title }) {
  const navigation = useNavigate()
  const classes = useStyles()

  return (
    <div className='row py-4 pt-md-0' style={{ background }}>
      <div
        className={`col-12 col-sm-6 d-flex align-items-center px-5 pt-1 pt-sm-5 pt-lg-0 ${
          textPosition === 'left' ? 'order-2 order-sm-1' : ' order-2'
        } `}
      >
        <div className={classes.textContainer}>
          <div className='web-subhead d-flex pb-4' style={{ fontSize: 48, lineHeight: 1 }}>{title}</div>
          <div className='slide-img-text d-flex pb-3' style={{fontSize: 20}}>{text}</div>
          {button === false
            ? (
                ''
              )
            : (
              <div className='d-flex justify-content-start' style={{ gap: 16 }}>
                <Button
                  variant='outlined'
                  className={`${classes.button}`}
                  onClick={() => {
                    navigation(buttonLink)
                  }}
                >
                  {buttonText || 'Learn more'}
                </Button>
                {secondaryButtonText && (
                  <Button
                    variant='outlined'
                    className={`${classes.button}`}
                    onClick={() => {
                      navigation(secondaryButtonLink)
                    }}
                    >
                    {secondaryButtonText}
                  </Button>
                )}
              </div>
              )}
        </div>
      </div>
      <motion.div
        {...Animations.FADE()}
        style={{ position: 'relative' }}
        className={`col-12 col-sm-6 d-flex align-items-center justify-content-center px-2 px-md-5 ${
          textPosition === 'left' ? 'order-1 order-sm-2' : 'order-1'
        }`}
      >
        {animation && (
          <div className={classes.animationContainer}>
            {animation}
          </div>
        )}
        <img
          src={image}
          alt='slide'
          className={classes.image}
          stye={{ maxWidth: '100%' }}
        />
      </motion.div>
    </div>
  )
}
