import React, { useState } from 'react'
import { Accordion as AccordionMUI, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

import useStyles from './Accordion.styles'

export default function Accordion ({ content, expandedIndex }) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState('panel' + expandedIndex)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      {content.map(({ title, textLine1, textLine2 }, index) => {
        return (
          <AccordionMUI
            key={index}
            className={expanded === 'panel' + index ? 'accordion-item-active' : 'accordion-item'}
            expanded={expanded === 'panel' + index}
            onChange={handleChange('panel' + index)}
          >
            <AccordionSummary
              expandIcon={
                expanded === 'panel' + index
                  ? (
                    <RemoveCircleOutline className={classes.icon} />
                    )
                  : (
                    <AddCircleOutline className={classes.icon} />
                    )
              }
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <div className='accordion-title text-start'>{title}</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className='accordion-text'>{textLine1}</div>
            </AccordionDetails>
            {textLine2 && (
              <AccordionDetails>
                <div className='accordion-text'>{textLine2}</div>
              </AccordionDetails>
            )}
          </AccordionMUI>
        )
      })}
    </div>
  )
}
