import React from 'react'

import RedCardBlock from '../../components/red-card-block/RedCardBlock'
import { TravelExample, TravelHeader, TravelServices } from './components'
import useStyles from './Travel.styles'

export default function Travel () {
  const classes = useStyles()

  return (
    <div>
      <TravelHeader />
      <TravelServices />
      <TravelExample />
      {/* <img alt='travel' src={example} style={{ width: '100%' }} /> */}
      <div>
        <h1 className={classes.blueTitle}>Travel more, spend less</h1>
      </div>
      <RedCardBlock title='Want to learn more?' buttonText='Contact us' />
    </div>
  )
}
