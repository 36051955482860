import React from 'react'
import { motion } from 'framer-motion'

import label from '../../assets/images/employees/label.svg'
import Animations from '../../utils/animations'

export default function AnimatedLabel() {

  return (
    <motion.img src={label} alt='label' style={{ width: '90%' }}
      {...Animations.BOUNCE({ delay: 1 })}
    />
  )
}
