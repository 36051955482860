import React from 'react'

import boosting from '../../assets/images/home/boosting.webp'
import Footnote from '../../components/footnote/Footnote'
import SlideImage from '../../components/slide-image/SlideImage'
import RedCardBlock from '../../components/red-card-block/RedCardBlock'
import { HomeBenefits, HomeBlogPreview, HomeCarousel, HomeDeal, HomeDescription } from './components'
import Paths from '../../utils/paths'
import useStyles from './Home.styles'

export default function Home () {
  const classes = useStyles({ width: window.innerWidth })

  return (
    <div>
      <HomeCarousel />
      <HomeDeal />
      <div className={classes.blueBlock}>
        <h1 className={classes.blueBlockTitle}>Unlike any other<br/>financial tool</h1>
      </div>
      <HomeDescription />
      <HomeBenefits />
      <SlideImage
        title='Boosting employee financial wellness is better for your bottom line'
        text={<div>
          <p>By offering a diverse range of low-risk financial tools, we help employees take big steps to improve their financial lives.</p>
          <p>It's no surprise that talented <b>people want to join, and stay at, companies who care.</b></p>
        </div>}
        image={boosting}
        buttonLink={Paths.BENEFITS}
      />
      <Footnote notes={['(1) Multikrd LLC is a financial technology company and not a FDIC-insured bank. Checking accounts and the Multikrd Visa® Debit Card are provided by Lewis & Clark Bank, Member FDIC. Funds deposited into the checking account may be eligible for up to $250,000 of FDIC insurance. The FDIC’s deposit insurance coverage only protects against the failure of a FDIC-insured depository institution.']} />
      <RedCardBlock title='Ready to transform your workplace?' buttonText='Get a demo' />
      <HomeBlogPreview />
    </div>
  )
}
