import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  comparisonContainer: {
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },
  incentivesButton: {
    backgroundColor: 'black !important',
    borderRadius: 30,
    color: 'white !important',
    paddingBottom: 10,
    paddingInline: 25,
    paddingTop: 10,
    textTransform: 'none',
    width: '10rem'
  },
  incentivesContainer: {
    display: 'flex',
    marginBottom: 40,
    paddingBottom: 32,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 32,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: 16,
      paddingBottom: 16,
      paddingTop: 16
    }
  },
  incentivesImageContainer: {
    flex: 1.2,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  incentivesImage: {
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '40%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    }
  },
  incentivesPopContainer: {
    display: 'flex', 
    flexDirection: 'row', 
    position: 'absolute', 
    top: 'calc(20%)',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      left: 0,
      right: 0
    },
  },
  incentivesPopImage: {
    [theme.breakpoints.down('md')]: {
      width: '90%'
    },
  },
  incentivesSubtitle: {
    fontFamily: 'Work Sans',
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    }
  },
  incentivesTitle: {
    fontFamily: 'Work Sans',
    fontSize: 70,
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
    }
  },
  incentivesTitleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: 32,
    justifyContent: 'center',
    paddingBottom: 16,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 16,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      gap: 16,
      paddingLeft: 8,
      paddingRight: 8,
    }
  },
  integrationImage: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  stepsSection: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      position: 'relative',
      left: 0,
      right: 0,
      margin: '0 auto',
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  stepsCard: {
    backgroundColor: '#003a78',
    color: '#ffffff',
    padding: 20,
    borderRadius: 10,
    textAlign: 'center',
    width: 200,
    height: 150
  },
  stepNumber: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: 10
  },
  stepsButton: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#ffffff',
    color: '#003a78',
    border: 'none',
    borderRadius: 5,
    cursor: 'pointer'
  }
}), { classNamePrefix: 'HowItWorks' })