import React from 'react'
import { IconButton } from '@material-ui/core'

import { facebook, linkedin, youtube } from '../../../assets/icons'
import { openWindow } from '../../../utils/helpers'
import useStyles from '../Footer.styles'

const FACEBOOK = 'https://www.facebook.com/Multikrd'
const LINKEDIN = 'https://www.linkedin.com/company/multikrd-llc/'
const YOUTUBE = 'https://www.youtube.com/channel/UCidMjaOw4jGP7zLsSpEpoVg/featured'

export default function FooterSocial() {
  const classes = useStyles()

  return (
    <div className='d-flex col flex-column'>
      <p className={classes.socialTitle}>Follow Us!</p>
      <div>
        <IconButton
          onClick={() => openWindow(YOUTUBE)}
          aria-label='Youtube'
        >
          <img src={youtube} alt='Youtube' />
        </IconButton>
        <IconButton
          onClick={() => openWindow(FACEBOOK)}
          aria-label='Facebook'
        >
          <img src={facebook} alt='Facebook' />
        </IconButton>
        <IconButton
          onClick={() => openWindow(LINKEDIN)}
          aria-label='In'
        >
          <img src={linkedin} alt='In' />
        </IconButton>
      </div>
    </div>
  )
}