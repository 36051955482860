import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  cardSubtitle: {
    fontSize: 22,
    textAlign: 'left',
    marginTop: 16,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 32,
      paddingRight: 32,
      textAlign: 'center'
    }
  },
  cardTitle: {
    fontSize: 50,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
      paddingLeft: 32,
      paddingRight: 32,
      textAlign: 'center'
    }
  }
}), { classNamePrefix: 'Employees' })