import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { motion } from 'framer-motion'

import dealCard from '../../../assets/images/home/deal-card.webp'
import dealBalance from '../../../assets/images/home/deal-balance.svg'
import dealCashback from '../../../assets/images/home/deal-cashback.svg'
import dealWageAccess from '../../../assets/images/home/deal-wage-access.svg'
import Animations from '../../../utils/animations'
import Paths from '../../../utils/paths'
import useStyles from '../Home.styles'

export default function HomeDeal () {
  const classes = useStyles()
  const navigation = useNavigate()

  return (
    <div className={classes.dealContainer}>
      <div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <motion.div
            {...Animations.POP({ duration: 0.8, delay: 0.6 })}
            className={classes.dealWageAccess}
            // style={{ margin: -20, marginTop: 60, marginLeft: 20}}
          >
            <img src={dealWageAccess} alt='Wage Access' className={classes.dealImagePop} />
          </motion.div>
          <motion.div
            {...Animations.POP({ duration: 0.8, delay: 0.8 })}
            className={classes.dealCashback}
            // style={{margin: -20 }}
          >
            <img src={dealCashback} alt='Cashback' className={classes.dealImagePop} />
          </motion.div>
          <motion.div
            {...Animations.POP({ duration: 0.8, delay: 1 })}
            // style={{marginTop: 60}}
            className={classes.dealBalance}
          >
            <img src={dealBalance} alt='Card Balance' className={classes.dealImagePop} />
          </motion.div>
        </div>
        <motion.div className={classes.dealImageContainer}
          initial={{ x: '-100%' }} whileInView={{ x: 0 }} transition={{duration: 0.8}} 
          >
          <img src={dealCard} className={classes.dealImage} alt='MK Card' />
        </motion.div>
      </div>
      <div className={classes.dealTitleContainer}>
        <h1 className={classes.dealTitle}>It’s a<br /><b>win-win</b> deal</h1>
        <h2 className={classes.dealSubtitle}>Effectively recognize, reward and retain all the great people you hire.</h2>
        <Button
          variant='outlined'
          className={classes.dealButton}
          onClick={() => navigation(Paths.CONTACT)}
        >
          Get in touch
        </Button>
      </div>
    </div>
  )
}