import React from 'react'
import { Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import header1 from '../../../assets/images/benefits/header-1.webp'
import header2 from '../../../assets/images/benefits/header-2.webp'
import header3 from '../../../assets/images/benefits/header-3.webp'
import header4 from '../../../assets/images/benefits/header-4.webp'
import headerShape from '../../../assets/images/shapes/red-bottom-right.svg'
import ImgHeaderSm from '../../../assets/images/benefits-img-header-sm.png'
import Paths from '../../../utils/paths'
import Animations from '../../../utils/animations'
import useStyles from '../Benefits.styles'

export default function BenefitsHeader () {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className='d-flex pb-5 header-container row px-1 px-sm-5 px-md-1'>
      <img src={headerShape} alt='figure' className='figure-header-benefits' />
      <div className='col-12 col-md-6 px-1 px-lg-5 pt-1 d-none d-md-flex align-items-center'>
        <div className='row px-1 px-lg-5 pt-1'>
          <motion.img src={header1} alt='img' className='col-6 benefits-img-header' 
            {...Animations.FADE({ duration: 0.3, delay: 0.2 })}
          />
          <motion.img src={header2} alt='img' className='col-6 benefits-img-header' 
            {...Animations.FADE({ duration: 0.3, delay: 0.4 })}
          />
          <motion.img src={header3} alt='img' className='col-6 benefits-img-header' 
            {...Animations.FADE({ duration: 0.3, delay: 0.6 })}
          />
          <motion.img src={header4} alt='img' className='col-6 benefits-img-header' 
            {...Animations.FADE({ duration: 0.3, delay: 0.8 })}
          />
        </div>
      </div>
      <div className='col-12 px-1 pt-1 d-flex d-md-none align-items-center justify-content-center'>
        <img src={ImgHeaderSm} alt='header-img' style={{ width: '100%' }} />
      </div>
      <div className='col-12 col-md-6 d-flex align-items-center p-1 pt-5 p-md-5'>
        <div className='d-flex flex-column p-1 p-lg-5 header-benefits-text-container'>
          <h1 className='web-header pb-4'>
            Motivated employees are <b>happier</b>, more <b>engaged</b>, and <b>more productive</b>
          </h1>
          <h2 className='large-text pb-3'>
            By offering Multikrd to your employees you will enrich and reward those who join your team, contribute fully, and stick around.
          </h2>
          <div className='d-flex justify-content-start pt-3'>
            <Button
              variant='outlined'
              className={classes.buttonSecondaryBlack}
              onClick={() => navigate(Paths.CONTACT)}
              style={{ zIndex: '4' }}
            >
              Get a demo
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}