import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  button: {
    backgroundColor: 'black !important',
    borderRadius: 30,
    color: 'white !important',
    minWidth: '10rem',
    marginBottom: 8,
    marginTop: 8,
    paddingBottom: 10,
    paddingInline: 25,
    paddingTop: 10,
    textTransform: 'none'
  },
  cardContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    paddingLeft: 40,
    paddingRight: 40,
    width: '80%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      padding: 0,
      width: '100%'
    }
  },
  container: {
    backgroundColor: '#F1645D',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      paddingBottom: 32,
    }
  },
  subtitle: {
    marginTop: 16,
    fontSize: 25,
    lineHeight: 1,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      textAlign: 'center'
    }
  },
  textContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: 40,
    [theme.breakpoints.down('md')]: {
      alignItems: 'center'
    }
  },
  title: { 
    fontSize: 60, 
    lineHeight: 1,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 30, 
      textAlign: 'center'
    }
  }
}), { classNamePrefix: 'RedCardBlock' })