import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  blueTitle: {
    color: '#0097EC',
    fontSize: 64,
    fontWeight: 'bold',
    paddingBottom: 56,
    paddingTop: 56,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      paddingBottom: 32,
      paddingTop: 32,
    }
  },
  categoriesContainer: {
    display: 'flex',
    marginBottom: 40,
    paddingBottom: 32,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 32,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16
    }
  },
  categoriesDisclaimer: {
    // bottom: '-3rem',
    // position: 'absolute'
  },
  categoriesIconsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: 32,
    position: 'relative',
    width: '80%',
    [theme.breakpoints.down('md')]: {
      gap: 16,
    }
  },
  categoriesIcon: {
    alignItems: 'center',
    display: 'flex',
    gap: 32,
    justifyContent: 'flex-start',
    paddingBottom: 32,
    paddingLeft: 56,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      paddingLeft: 32
    }
  },
  categoriesIconImage: {
    height: 80,
    [theme.breakpoints.down('md')]: {
      height: 56
    }
  },
  categoriesImageContainer: {
    display: 'flex'
  },
  categoriesImage: {
    margin: '0 auto',
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '60%'
    }
  },
  categoriesShape: {
    bottom: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: -1
  },
  categoriesSubtitle: {
    fontFamily: 'Work Sans',
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      marginBottom: 16
    }
  },
  categoriesTitle: {
    fontFamily: 'Work Sans',
    fontSize: 56,
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
    }
  },
  categoriesTitleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingBottom: 16,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 16,
    textAlign: 'left'
  },
  headerContainer: {
    marginBottom: 32,
    position: 'relative'
  },
  headerImage: {
    cursor: 'pointer',
    width: '90%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  headerNotification: {
    left: 0,
    margin: '0 auto',
    position: 'absolute',
    right: 0,
    top: 56,
    [theme.breakpoints.down('md')]: {
      top: 16,
      width: '40%'
    }
  }
}), { classNamePrefix: 'Discounts' })