import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  button: {
    textTransform: 'none',
    fontFamily: 'Work Sans'
  },
  buttonBlack: {
    backgroundColor: 'black !important',
    borderRadius: 30,
    color: 'white !important',
    marginRight: 5,
    paddingBottom: 10,
    paddingInline: 5,
    paddingTop: 10,
    textTransform: 'none',
    width: '8rem',
  },
  buttonWhite: {
    backgroundColor: 'transparent',
    borderRadius: 30,
    color: 'black',
    paddingBottom: 10,
    paddingTop: 10,
    textTransform: 'none',
    width: '8rem'
  },
  sideMenu: {
    minWidth: 50,
    maxWidth: 500
  }
}), { classNamePrefix: 'Navbar' })