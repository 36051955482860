export const Animations = {
  BOUNCE: ({ delay = 0, stiffness = 500, damping = 10 } = {}) => ({
    initial: { y: -100, opacity: 0 },
    whileInView: { y: 0, opacity: 1 },
    transition: {
      type: 'spring',
      stiffness,
      damping,
      delay
    }
  }),
  FADE: ({ delay = 0, duration = 0.5 } = {}) => ({
    initial: { opacity: 0.1 },
    whileInView: { opacity: 1 },
    transition: { duration, delay },
    viewport: { amount: 'all', once: false }
  }),
  FALLING_LEAF: ({
    delay = 0,
    duration = 5,
    direction = 'left',
    endingX = 0,
    endingY = '60%',
    scale
  } = {}) => {
    const x =
      direction === 'left'
        ? ['30%', '-60%', '50%', '-30%', '30%', '-30%', '20%', 0, endingX]
        : ['-30%', '50%', '-60%', '30%', '-30%', '20%', '-30%', 0, endingX]
    const rotate = direction === 'left' ? [0, 10, -10, 5, -5, 0] : [0, -10, 10, -5, 5, 0]

    return {
      initial: { opacity: 0, x: -50, y: -100, rotate: 0, scale: 0.7 },
      whileInView: {
        opacity: 1,
        x,
        y: ['-10%', '10%', endingY],
        rotate,
        scale
      },
      transition: {
        opacity: { duration: 0.5, delay },
        delay,
        duration,
        ease: 'easeInOut'
      }
    }
  },
  POP: ({ delay = 0, duration = 0.5 } = {}) => ({
    initial: { opacity: 0, scale: 0.5 },
    whileInView: { opacity: 1, scale: 1 },
    transition: { duration, delay }
  }),
  ROTATE: ({ delay = 0, duration = 0.5 } = {}) => ({
    initial: { opacity: 0, rotate: 0 },
    whileInView: { opacity: 1, rotate: -360 },
    transition: { duration, delay }
  }),
  SHAKE: ({ delay = 0, duration = 0.5 } = {}) => ({
    initial: { x: 0, y: 0 },
    whileInView: {
      x: [0, -10, 10, -10, 10, -10, 10, 0],
      y: [0, -5, 5, -5, 5, -5, 5, 0]
    },
    transition: { duration, delay, ease: 'easeInOut' }
  }),
  SLIDE_DOWN: ({ delay = 0, duration = 0.5, y = -100 } = {}) => ({
    initial: { opacity: 0, y },
    whileInView: { opacity: 1, y: 0 },
    transition: { duration, delay },
    viewport: { once: false }
  }),
  SLIDE_LEFT: ({ delay = 0, duration = 0.5, x = 100 } = {}) => ({
    initial: { opacity: 0, x },
    whileInView: { opacity: 1, x: 0 },
    transition: { duration, delay },
    viewport: { once: false }
  }),
  SLIDE_RIGHT: ({ delay = 0, duration = 0.5, x = -100 } = {}) => ({
    initial: { opacity: 0, x },
    whileInView: { opacity: 1, x: 0 },
    transition: { duration, delay },
    viewport: { once: false }
  }),
  SLIDE_UP: ({ delay = 0, duration = 0.5, y = 100 } = {}) => ({
    initial: { opacity: 0, y },
    whileInView: { opacity: 1, y: 0 },
    transition: { duration, delay },
    viewport: { once: false }
  }),
  TWINKLE: ({ delay = 0, duration = 1.5 } = {}) => ({
    initial: { opacity: 0.8, scale: 0 },
    whileInView: {
      scale: [0, 1.2, 1],
      opacity: [0, 1, 0]
    },
    transition: {
      duration,
      delay,
      ease: 'easeInOut',
      repeat: Infinity
    }
  })
}

export default Animations
