import { makeStyles } from '@material-ui/core/styles'

const CAROUSEL_ASPECT_RATIO = 1.82

const getCarouselHeight = () => window.innerWidth / CAROUSEL_ASPECT_RATIO

export default makeStyles(theme => ({
  benefitsAccordionContainer: {
    flex: 1.2,
    paddingLeft: 56,
    paddingRight: 0,
    paddingTop: 40,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 16
    }
  },
  benefitsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 40,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      marginBottom: 16
    }
  },
  benefitsVideo: {
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '80%'
    }
  },
  benefitsVideoContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    paddingLeft: 40,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      padding: 0
    }
  },
  blogButton: {
    margin: 0,
    minWidth: 40,
    paddingBottom: 8,
    paddingTop: 8,
    width: 8,
  },
  blogLink: {
    maxWidth: '100%'
  },
  blueBlock: {
    backgroundColor: '#004598',
    marginBottom: 40,
    padding: 40,
    [theme.breakpoints.down('md')]: {
      marginBottom: 16,
      padding: 16
    }
  },
  blueBlockTitle: {
    color: 'white',
    fontFamily: 'Work Sans',
    fontSize: 80,
    [theme.breakpoints.down('md')]: {
      fontSize: 40
    }
  },
  carouselContainer: {
    position: 'relative'
  },
  carouselSubtitle: {
    fontFamily: 'Work Sans',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    }
  },
  carouselTitle: {
    fontFamily: 'Work Sans',
    fontSize: 60,
    fontWeight: 'bold',
    marginBottom: 16,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
      marginBottom: 8
    }
  },
  carouselTitleConainer: {
    alignItems: 'center', 
    display: 'flex', 
    flexDirection: 'column',
    height: getCarouselHeight(),
    justifyContent: 'center', 
    position: 'absolute', 
    top: 0, 
    width: '100%', 
    zIndex: 2
  },
  dealButton: {
    backgroundColor: 'black !important',
    borderRadius: 30,
    color: 'white !important',
    paddingBottom: 10,
    paddingInline: 25,
    paddingTop: 10,
    textTransform: 'none',
    width: '10rem'
  },
  dealContainer: {
    display: 'flex',
    marginBottom: 40,
    paddingBottom: 32,
    paddingTop: 32,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: 16,
      paddingBottom: 16,
      paddingTop: 16
    }
  },
  dealImageContainer: {
    flex: 1.2,
    display: 'flex',
    paddingBottom: 40,
    paddingTop: 40,
  },
  dealImage: {
    width: '80%'
  },
  dealImagePop: {
    width: '100%'
  },
  dealBalance: {
    marginTop: 60,
    [theme.breakpoints.down('md')]: {
      marginRight: 20
    }
  },
  dealCashback: {
    margin: -20
  },
  dealWageAccess: {
    marginBottom: -20,
    marginLeft: 20,
    marginRight: -20,
    marginTop: 60,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  },
  dealSubtitle: {
    fontFamily: 'Work Sans',
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    }
  },
  dealTitle: {
    fontFamily: 'Work Sans',
    fontSize: 70,
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
    }
  },
  dealTitleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingBottom: 16,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 16,
    textAlign: 'left'
  },
  descriptionGif: {
    display: 'initial',
    position: 'absolute',
    right: 'calc(50%)',
    top: 20,
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'initial',
      right: 0,
      width: '50%'
    }
  }
}), { classNamePrefix: 'Home' })