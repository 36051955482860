import React from 'react'
import { motion } from 'framer-motion'

import coin from '../../assets/images/employees/coin.svg'
import Animations from '../../utils/animations'

export default function AnimatedCoin() {

  return (
    <motion.img src={coin} alt='coin' style={{ width: '90%' }}
      {...Animations.ROTATE({ delay: 1 })}
    />
  )
}
