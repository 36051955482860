import React from 'react'

import useStyles from '../Card.styles'

const ServiceItem = ({title, description}) => {
  const classes = useStyles()

  return (
    <div className={classes.servicesItem}>
      <h4 className={classes.servicesItemTitle}>{title}</h4>
      <p className={classes.servicesItemDescription}>{description}</p>
    </div>
  )
}

export default function CardServices () {
  const classes =   useStyles()

  return (
    <div className={classes.servicesContainer}>
      <h3 className={classes.servicesTitle}>Gain access to a bank account with direct deposit and a versatile Debit Card.</h3>
      <div className={classes.servicesItemsContainer}>
        {services.map(service => (
          <ServiceItem {...service} />
        ))}
      </div>
    </div>
  )
}

const services = [
  {
    title: 'Send and Receive Transfers⁽²⁾',
    description: 'Easily transfer money to others directly from your account. Accept transfers just like in a regular deposit account.'
  },
  {
    title: 'Free Earned Wage Access Requests⁽²⁾',
    description: 'Earned Wage Access is free when you set up the lesser amount between $500 or half of your paycheck to be deposited into your MK Account.'
  },
  {
    title: 'Physical and Digital Card Options',
    description: 'Access your MK Card through Apple Pay or Google Pay, or keep a physical card in your wallet.'
  },
  {
    title: 'Easy Application and Quick Response on Approval',
    description: 'Most applications are approved in minutes, making it easy to get started.'
  },
  {
    title: 'User-Friendly Management',
    description: 'Manage your card and account from our app or web platform with ease.'
  },
  {
    title: 'Dedicated Customer Support',
    description: 'Our support team is ready to assist with any questions or concerns.'
  }
]