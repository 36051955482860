import React from 'react'

import RedCardBlock from '../../components/red-card-block/RedCardBlock'
import { DiscountsCategories, DiscountsHeader } from './components'
import useStyles from './Discounts.styles'

export default function Discounts () {
  const classes = useStyles()

  return (
    <div>
      <DiscountsHeader />
      <DiscountsCategories />
      <div>
        <h1 className={classes.blueTitle}>Maximize your savings!</h1>
      </div>
      <RedCardBlock title='Want to learn more?' buttonText='Contact us' />
    </div>
  )
}
