import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { motion } from 'framer-motion'

import card from '../../assets/images/card.svg'
import { redirect } from '../../utils/helpers'
import Paths from '../../utils/paths'
import useStyles from './RedCardBlock.styles'

const PLATFORM_LOGIN = 'https://platform.accesswages.com/login'

export default function RedCardBlock({ buttonText, subtitle, title, goToPlatform = false }) {
  const classes = useStyles()
  const navigate = useNavigate()
  
  return (
    <div className={classes.container}>
      <div className={classes.cardContainer}>
        <motion.img alt='Card' src={card} initial={{ scale: 0.1 }} whileInView={{ scale: 1 }} transition={{ duration: 0.5 }} />
      </div>
      <div className={classes.textContainer}>
        <p className={classes.title}>
          {title}
        </p>
        <Button
          variant='outlined'
          className={classes.button}
          onClick={() => {
            if (goToPlatform) {
              redirect(PLATFORM_LOGIN)
            } else {
              navigate(Paths.CONTACT)
            }
          }}
        >
          {buttonText}
        </Button>
        {subtitle && (
          <p className={classes.subtitle}>
            {subtitle}
          </p>
        )}
      </div>
    </div>
  )
}
