import React from 'react'
import Button from '@material-ui/core/Button'
import { useNavigate } from 'react-router-dom'

import Paths from '../../../utils/paths'
import useStyles from '../Footer.styles'

export default function FooterNavigation() {
  const navigate = useNavigate()
  const classes = useStyles()

  return (
    <div className={classes.navigationContainer}>
      <div className='d-flex col flex-column col-md-3 col-6 pb-3 pb-md-0'>
        <Button
          onClick={() => navigate(Paths.BENEFITS)}
          className={classes.navigationButton}
        >
          For Companies
        </Button>
        <Button
          onClick={() => navigate(Paths.HOW_IT_WORKS)}
          className={classes.navigationButton}
        >
          How it works
        </Button>
        <Button
          onClick={() => navigate(Paths.BENEFITS)}
          className={classes.navigationButton}
        >
          Features
        </Button>
      </div>
      <div className='d-flex col flex-column col-md-3 col-6 pb-3 pb-md-0'>
        <Button
          onClick={() => navigate(Paths.EMPLOYEES)}
          className={classes.navigationButton}
        >
          For Employees
        </Button>
        <Button
          className={classes.navigationButton}
        >
          Earned Wage Access
        </Button>
        <Button
          className={classes.navigationButton}
        >
          Tip Disbursements
        </Button>
        <Button
          className={classes.navigationButton}
        >
          The MK Card
        </Button>
        <Button
          className={classes.navigationButton}
        >
          Discounts
        </Button>
        <Button
          className={classes.navigationButton}
        >
          Cashback
        </Button>
        <Button
          className={classes.navigationButton}
        >
          Travel
        </Button>
      </div>
      <div className='d-flex col flex-column col-md-3 col-6 pb-3 pb-md-0'>
        <Button
          onClick={() => navigate(Paths.BLOG)}
          className={classes.navigationButton}
        >
          Resources
        </Button>
        <Button
          onClick={() => navigate(Paths.BLOG)}
          className={classes.navigationButton}
        >
          Blog
        </Button>
        <Button
          onClick={() => navigate(Paths.MEDIA)}
          className={classes.navigationButton}
        >
          Media Center
        </Button>
        <Button
          onClick={() => navigate(Paths.FAQS)}
          className={classes.navigationButton}
        >
          FAQs
        </Button>
      </div>
      <div className='d-flex col flex-column col-md-3 col-6 pb-3 pb-md-0'>
        <Button
          onClick={() => navigate(Paths.ABOUT_US)}
          className={classes.navigationButton}
        >
          About us
        </Button>
        <Button
          onClick={() => navigate(Paths.CONTACT)}
          className={classes.navigationButton}
        >
          Get a demo
        </Button>
        <Button
          onClick={() => navigate(Paths.CONTACT)}
          className={classes.navigationButton}
        >
          Contact us
        </Button>
      </div>
    </div>
  )
}