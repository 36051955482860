import React from 'react'
import { motion } from 'framer-motion'

import spark from '../../assets/images/employees/spark.svg'
import Animations from '../../utils/animations'

const sparks = [
  { duration: 1.5, delay: 0, x: '-10%', y: '30%' },
  { duration: 1.8, delay: 0.4, x: '-60%', y: '80%'  },
  { duration: 1.5, delay: 0.8, x: '-90%', y: '20%' },
]

export default function AnimatedSparks() {

  return (
    <>
      {sparks.map(({delay, x, y}, index) => (
        <motion.img key={index} src={spark} alt='sparks' style={{ position: 'absolute', width: 160, x, y }}
          {...Animations.TWINKLE({ delay })}
        />
      ))}
    </>
  ) 
}
