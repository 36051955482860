import React from 'react'
import Accordion from '../../../components/accordion/Accordion'
import video from '../../../assets/videos/benefits.mp4'
import useStyles from '../Home.styles'

export default function HomeBenefits() {
  const classes = useStyles()

  return (
    <div className={classes.benefitsContainer}>
      <div className={classes.benefitsAccordionContainer}>
        <div className='web-subhead pb-5'><b>Benefits for your staff:</b></div>
        <div className='pb-5 mb-5'>
          <Accordion content={AccordionContent} expandedIndex={0} />
        </div>
        <div className='ps-1 ps-lg-5 ms-5 d-flex flex-column'>
        </div>
        <div />
      </div>
      <div className={classes.benefitsVideoContainer}>
        <video
          src={video}
          controls
          autoPlay
          className={classes.benefitsVideo}
        />
      </div>
    </div>
  )
}

const AccordionContent = [
  {
    title: 'Earned Wage Access',
    textLine1: 'Earned Wage Access (EWA) is a financial service that allows employees to access a portion of their earned wages before their scheduled payday. This flexible benefit helps employees manage unexpected expenses and financial emergencies, reducing the need for high-interest loans or credit card debt.', 
    textLine2: 'With EWA, employees can enjoy greater financial security and peace of mind, knowing they can access their hard-earned money when they need it most.'
  },
  {
    title: 'The MK Card',
    textLine1: 'The MK Card by Multikrd ⁽¹⁾ provides seamless access to earnings and great financial benefits. Available in physical and digital formats, it can be used with Apple Pay or Google Pay mobile wallets. Features of the card and account include sending money, receiving transfers, and early direct deposit. Easy application and excellent support make it a convenient financial tool.',
  },
  {
    title: 'Personalized Promotions and Savings',
    textLine1: 'Aligned with one of America\'s largest promotions network, we offer a wide range of discounts, deals, and cashback opportunities at over a million retail and travel partners.',
    textLine2: 'Our users enjoy perks worldwide, with rewards that suit their preferences.'
  }
]
