import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  buttonSecondaryBlack: {
    backgroundColor: 'black !important',
    borderRadius: 30,
    color: 'white !important',
    paddingBottom: 10,
    paddingInline: 25,
    paddingTop: 10,
    textTransform: 'none',
    width: '10rem'
  },
  accordionCaption: {
    fontSize: 22
  },
  accordionCaptionAccent: {
    color: '#0097EC',
    fontSize: 22,
    fontWeight: 'bold'
  },
  accordionCaptionContainer: {
    margin: '0 auto',
    textAlign: 'left',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '80%'
    }
  },
  accordionCaptionImage: {
    marginBottom: 8,
    width: '100%'
  },
  accordionColumn: {
    flex: 1
  },
  accordionContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 56,
    marginBottom: 40,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: 32,
      marginBottom: 16
    }
  },
  accordionEmployersContainer: {
    flex: 1.2,
    paddingLeft: 56,
    paddingRight: 0,
    paddingTop: 40,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 16
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  accordionEmployersImage: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 32,
      width: '100%'
    }
  },
  accordionEmployeesContainer: {
    flex: 1.2,
    paddingLeft: 0,
    paddingRight: 56,
    paddingTop: 40,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 16
    }
  },
  accordionTitle: {
    textAlign: 'left'
  },
  accordionSubtitle: {
    marginBottom: 56,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      marginBottom: 32
    } 
  },
}), { classNamePrefix: 'Benefits' })