import React from 'react'
import Button from '@material-ui/core/Button'

import { openWindow } from '../../../utils/helpers'
import Paths from '../../../utils/paths'
import useStyles from '../Navbar.styles'

const PLATFORM_LOGIN = 'https://platform.accesswages.com/login'

export default function NavbarSideMenu({ pathname, onClick, onClose }) {
  const classes = useStyles()

  return (
    <>
      <Button
        variant='outlined'
        onClick={() => {
          if (pathname === Paths.CUSTOMERS) {
            onClick('signup', Paths.SIGNUP)
          } else {
            onClick('contact', Paths.CONTACT)
          }
          onClose()
        }}
        className={`${classes.buttonBlack} button-mui`}
      >
        {pathname === Paths.CUSTOMERS ? 'Signup now' : 'Contact us'}
      </Button>

      <Button
        variant='outlined'
        className={`${classes.buttonWhite} d-md-inline button-mui`}
        onClick={() => openWindow(PLATFORM_LOGIN)}
      >
        Sign in
      </Button>
    </>
  )
}