import React from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import Icon1 from '../../../assets/images/employees/card-wage-access.svg'
import Icon2 from '../../../assets/images/employees/card-cashback.svg'
import Icon3 from '../../../assets/images/employees/card-wallet.svg'
import cardImage from '../../../assets/images/card.svg'
import Paths from '../../../utils/paths'
import useStyle from '../Employees.styles'

export default function EmployeesCard({ onClick }) {
  const classes = useStyle()
  const navigate = useNavigate()

  const hoverAnimation = {
    scale: 1.1,
    transition: { duration: 0.2 },
  }

  return (
    <div className='slide-employees-blue pb-5 mt-4'>
      <div className='row'>
        <motion.img className='col-12 col-sm-6 p-5' src={cardImage} alt='MK Card' initial={{scale: 0.1}} whileInView={{scale: 1}} transition={{duration: 0.5}} />
        <div className='col-12 col-sm-6 d-flex align-items-center'>
          <div
            className='d-flex flex-column slide-employees-blue-header-container'
            style={{ color: 'white' }}
          >
            <div className={classes.cardTitle}>Introducing the MK Card⁽¹⁾</div>
            <div className={classes.cardSubtitle}>
              It’s never been easier to improve your financial well-being.
            </div>
          </div>
        </div>
      </div>
      <div className='row pb-5'>
        <div
          className='col-12 col-sm-6 col-md-4 py-sm-2 px-5'
          style={{ color: 'white' }}
        >
          <motion.img
            whileHover={hoverAnimation}
            className='py-0 mb-3 mb-lg-0 mt-5 mt-sm-3 py-lg-5 slide-employees-blue-icon'
            src={Icon1}
            alt='wage-access'
          />
          <div className='web-subhead text-start px-3 pt-1 pb-4'>Easy access to pay and tip advances</div>
          <div className='text-regular text-start px-3'>
            Your MK Card is integrated with your earned wage advances and cashback—giving you <span style={{ fontWeight: 'bold', color: '#C1D82F'}}>quick, stress-free access</span> to your money when you need it.
          </div>
        </div>
        <div
          className='col-12 col-sm-6 col-md-4 py-sm-2 px-5'
          style={{ color: 'white' }}
        >
          <motion.img
            whileHover={hoverAnimation}
            className='py-0 mb-3 mb-lg-0 mt-5 mt-sm-3 py-lg-5 slide-employees-blue-icon'
            src={Icon2}
            alt='cashback'
          />
          <div className='web-subhead text-start px-3 pt-1 pb-4'>No more back and forth with cash</div>
          <div className='text-regular text-start px-3'>
            You can set up your payroll direct deposit and manage your finances from the app. Make <span style={{ fontWeight: 'bold', color: '#FDBA63' }}>online and in-person payments and all kind of transfers</span> using your MK Card and Account, and unlock additional benefits such as free wage access.
            <span
              style={{ cursor: 'pointer', textDecoration: 'none' }}
              onClick={onClick}
            >
              ⁽²⁾
            </span>
          </div>
        </div>
        <div
          className='col-12 col-sm-6 col-md-4 py-sm-2 px-5'
          style={{ color: 'white' }}
        >
          <motion.img
            whileHover={hoverAnimation}
            className='py-0 mb-3 mb-lg-0 mt-5 mt-sm-3 py-lg-5 slide-employees-blue-icon'
            src={Icon3}
            alt='wallet'
          />
          <div className='web-subhead text-start px-3 pt-1 pb-4'>We travel with you – always at hand</div>
          <div className='text-regular text-start px-3'>
            Access your MK card through <span style={{ fontWeight: 'bold', color: '#FF8080'}}>Apple Pay or Google Pay</span>, or simply keep a card in your wallet. Easy peasy.
          </div>
          <button onClick={() => navigate(Paths.CARD)} style={{ color: '#0097EC', borderColor: '#0097EC', borderRadius: 32, padding: 16, marginTop: 16}}>Learn more</button>
        </div>
      </div>
    </div>
  )
}