import React from 'react'
import { motion } from 'framer-motion'

import step1 from '../../../assets/images/wage-access/steps-1.svg'
import step2 from '../../../assets/images/wage-access/steps-2.svg'
import step3 from '../../../assets/images/wage-access/steps-3.svg'
import step4 from '../../../assets/images/wage-access/steps-4.svg'
import Footnote from '../../../components/footnote/Footnote'
import Animations from '../../../utils/animations'
import useStyles from '../WageAccess.styles'

export default function WageAccessSteps () {
  const classes = useStyles()

  return (
    <div style={{ paddingBottom: 40, paddingTop: 40, backgroundColor: '#CFEEFA' }}>
      <h1>How It Works</h1>
      <div className={classes.stepsSection}>
        <motion.img
          style={{cursor: 'pointer', zIndex: 2}}
          {...Animations.SLIDE_RIGHT({ delay: 0.3, duration: 0.3 })}
          src={step1}
        />
        <motion.img
          style={{ zIndex: 2 }}
          {...Animations.SLIDE_RIGHT({ delay: 0.6, duration: 0.3 })}
          src={step2}
        />
        <motion.img
          style={{ zIndex: 2 }}
          {...Animations.SLIDE_RIGHT({ delay: 0.9, duration: 0.3 })}
          src={step3}
        />
        <motion.img
          style={{ zIndex: 2 }}
          {...Animations.SLIDE_RIGHT({ delay: 1.2, duration: 0.3 })}
          src={step4}
        />
      </div>
      <Footnote notes={['Subject to Terms & Conditions.']} />
    </div>
  )
}