import React from 'react'

export default function Footnote({ notes = [], omitPadding = false }) {
  return (
    <div className='row pb-5'>
      <div className='col-6 px-5 pt-5' />
      {notes.map(note => (
        <div className={`py-1 employees-subtext ${omitPadding ? '' : 'px-5'}`}>
          {note}
        </div>
      ))}
    </div>
  )
}