import React, { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'

import carousel1 from '../../../assets/images/home/carousel-1.webp'
import carousel2 from '../../../assets/images/home/carousel-2.webp'
import carousel3 from '../../../assets/images/home/carousel-3.webp'
import carousel4 from '../../../assets/images/home/carousel-4.webp'
import carousel5 from '../../../assets/images/home/carousel-5.webp'

import useStyles from '../Home.styles'

const images = [
  carousel1,
  carousel2,
  carousel3,
  carousel4,
  carousel5
]
const IMAGE_ASPECT_RATIO = 1.82

export default function HomeCarousel() {
  const [carouselHeight, setCarouselHeight] = useState(window.innerWidth / IMAGE_ASPECT_RATIO)
  const classes = useStyles({ carouselHeight })

  const handleResize = () => setCarouselHeight(window.innerWidth / IMAGE_ASPECT_RATIO)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className={classes.carouselContainer} style={{height: carouselHeight}}>
      <div className={classes.carouselTitleConainer} style={{ height: carouselHeight }}>
        <h1 className={classes.carouselTitle}>Revolutionize Your Benefits,<br />Retain Your Talent</h1>
        <h3 className={classes.carouselSubtitle}>Multikrd helps boost the financial health of your employees.</h3>
      </div>
      <Carousel className='w-100 mb-5' interval={4000} animation='slide' duration={1500} autoPlay height={carouselHeight} he navButtonsAlwaysInvisible indicators={false}>
        {images.map((image, index) => (
          <img alt='Home Carousel' src={image} key={`home-carousel-${index}`} className='w-100' />
          ))}
      </Carousel>
    </div>
  )
}