import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Collapse, List, ListItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Close, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons'

import { B2C_FEATURE_ACTIVE } from '../../../utils/constants'
import Paths from '../../../utils/paths'
import useStyles from '../Navbar.styles'
import { NavbarButtons } from './index'

const currentDomain = window.location.hostname.split('.')[0]
const MULTIKRD_B2C_SUBDOMAIN = currentDomain === 'multikrd' ? 'consumer' : 'consumer-test'

export default function NavbarSideMenu({ isB2C, onClickItem, onClose }) {
  const classes = useStyles()
  const location = useLocation()
  const [openCompaniesCollapse, setOpenCompaniesCollapse] = useState(false)
  const [openEmployeesCollapse, setOpenEmployeesCollapse] = useState(false)
  const [openResourcesCollapse, setOpenResourcesCollapse] = useState(false)

  return (
    <Box
      role='presentation'
      onKeyDown={() => onClose()}
      className={`p-3 d-inline d-md-none navbar-mobile ${classes.sideMenu}`}
    >
      <List>
        <ListItem className='py-4 d-flex justify-content-end'>
          <Button onClick={onClose} style={{ border: '0px', padding: '1rem' }}>
            <Close />
          </Button>
        </ListItem>
        <ListItem
          button
          className='py-3 menu-item menu-collapse-button'
          onClick={() => setOpenCompaniesCollapse(!openCompaniesCollapse)}
        >
          <div className='menu-text'>For Companies</div>
          {openCompaniesCollapse
            ? (
              <KeyboardArrowDown />
              )
              : (
              <KeyboardArrowRight />
            )}
        </ListItem>
        <Collapse in={openCompaniesCollapse} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {companiesItems.map(({ name, link }) => (
              <ListItem
                key={name}
                button
                className='ps-3'
                onClick={() => {
                  onClickItem('For companies', link)
                  onClose()
                }}
              >
                <div className='menu-sub-text ps-3'>{name}</div>
              </ListItem>
            ))}
          </List>
        </Collapse>
        <ListItem
          button
          className='py-3 menu-item menu-collapse-button'
          onClick={() => setOpenEmployeesCollapse(!openEmployeesCollapse)}
        >
          <div className='menu-text'>For Employees</div>
          {openEmployeesCollapse
            ? (
              <KeyboardArrowDown />
            )
            : (
              <KeyboardArrowRight />
            )}
        </ListItem>
        <Collapse in={openEmployeesCollapse} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {employeesItems.map(({ name, link }) => (
              <ListItem
                key={name}
                button
                className='ps-3'
                onClick={() => {
                  onClickItem('For employees', link)
                  onClose()
                }}
              >
                <div className='menu-sub-text ps-3'>{name}</div>
              </ListItem>
            ))}
          </List>
        </Collapse>
        {B2C_FEATURE_ACTIVE && (
          <ListItem
            button
            className='py-3 menu-item'
            onClick={() => {
              if (isB2C) {
                onClickItem('customers', Paths.CUSTOMERS)
              } else {
                const consumerURL = window.location.origin.replace(
                  window.location.hostname,
                  `${MULTIKRD_B2C_SUBDOMAIN}.${window.location.hostname}`
                )
                if (!window.location.origin.includes(MULTIKRD_B2C_SUBDOMAIN)) {
                  window.location.replace(consumerURL)
                }
              }
              onClose()
            }}
          >
            <div className='menu-text'>For direct users</div>
          </ListItem>
        )}
        <ListItem
          button
          className='py-3 menu-item'
          onClick={() => {
            onClickItem('about-us', Paths.ABOUT_US)
            onClose()
          }}
        >
          <div className='menu-text'>About us</div>
        </ListItem>
        <ListItem
          button
          className='py-3 menu-item menu-collapse-button'
          onClick={() => setOpenResourcesCollapse(!openResourcesCollapse)}
          style={{ borderBottom: openResourcesCollapse ? '' : '1px solid #D2D2D2' }}
        >
          <div className='menu-text'>Resources</div>
          {openResourcesCollapse
            ? (
              <KeyboardArrowDown />
            )
            : (
              <KeyboardArrowRight />
            )}
        </ListItem>
        <Collapse
          in={openResourcesCollapse}
          timeout='auto'
          unmountOnExit
          style={{ borderBottom: openResourcesCollapse ? '1px solid #D2D2D2' : '' }}
        >
          <List component='div' disablePadding>
            {resourcesItems.map(({ name, link }) => (
              <ListItem
                key={name}
                button
                className='ps-3'
                onClick={() => {
                  onClickItem('For employees', link)
                  onClose()
                }}
              >
                <div className='menu-sub-text ps-3'>{name}</div>
              </ListItem>
            ))}
          </List>
        </Collapse>
        <div className='mt-5'>
          <NavbarButtons pathname={location.pathname} onClick={onClickItem} onClose={onClose} />
        </div>
      </List>
    </Box>
  )
}

const companiesItems = [
  { name: 'Benefits', link: Paths.BENEFITS },
  { name: 'How it works', link: Paths.HOW_IT_WORKS }
]

const employeesItems = [
  { name: 'Benefits', link: Paths.EMPLOYEES },
  { name: 'Early Wage Access', link: Paths.WAGE_ACCESS },
  { name: 'Tips On-Demand', link: Paths.TIPS },
  { name: 'The MK Card', link: Paths.CARD },
  { name: 'Discounts', link: Paths.DISCOUNTS },
  { name: 'Travel', link: Paths.TRAVEL },
  { name: 'Cashback', link: Paths.CASHBACK }
]

const resourcesItems = [
  { name: 'Blog', link: Paths.BLOG },
  { name: 'Media center', link: Paths.MEDIA },
  { name: 'FAQs', link: Paths.FAQS },
]