import React from 'react'

import SlideImage from '../../components/slide-image/SlideImage'
import Footnote from '../../components/footnote/Footnote'
import RedCardBlock from '../../components/red-card-block/RedCardBlock'
import AnimatedCoin from '../../components/animated-coin/AnimatedCoin'
import slide1 from '../../assets/images/cashback/slide-1.svg'
import slide2 from '../../assets/images/cashback/slide-2.webp'
import slide3 from '../../assets/images/cashback/slide-3.webp'
import { CashbackHeader, CashbackSteps } from './components'
import useStyles from './Cashback.styles'

export default function Cashback () {
  const classes = useStyles()

  return (
    <div>
      <CashbackHeader />
      <SlideImage
        animation={<AnimatedCoin />}
        textPosition='left'
        title={
          <p>
            Up to <b>20%</b> Cashback:
          </p>
        }
        text='Get up to 20% cashback on eligible purchases, allowing you to earn real cash with every transaction.'
        image={slide1}
        button={false}
      />
      <SlideImage
        title={
          <p>
            Over <b>30</b> Categories to choose from
          </p>
        }
        text='Earn cashback on purchases across a wide range of categories, from groceries to electronics, fashion to home goods.'
        image={slide2}
        button={false}
      />
      <SlideImage
        textPosition='left'
        title={
          <p>
            <b>8,000+</b> Online Merchants
          </p>
        }
        text='Earn cashback rewards when you shop from over 8,000 online merchants, ensuring you always find what you need with rewards.'
        image={slide3}
        button={false}
      />
      <Footnote notes={['Subject to Terms & Conditions.']} />
      <CashbackSteps />
      <div>
        <h1 className={classes.redTitle}>Turn Purchases into Savings</h1>
      </div>
      <RedCardBlock title='Ready to sign up?' buttonText='Create an Account' goToPlatform />
    </div>
  )
}
