import React from 'react'

import useStyles from '../HowItWorks.styles'

export default function HowItWorksComparison() {
  const classes = useStyles()

  return (
    <div className='d-flex flex-column px-sm-3 px-md-5 px-lg-1'>
      <div className='web-header text-center py-4'>We’re different</div>
      <div className='scroll'>
        <div className={`d-flex justify-content-center py-3 pb-5 scroll-div ${classes.comparisonContainer}`}>
          <div className='card card-white'>
            <div className='list-group-item web-subhead text-center text-md-end card-title-white py-3'>
              Current market providers
            </div>
            <div className='card-body'>
              {currentMakeProviders.map((text, index) => {
                return (
                  <div key={index} className='card-item'>
                    <div
                      className='text-regular text-center text-md-end'
                      style={{ width: '100%' }}
                    >
                      {text}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='card card-blue'>
            <li
              className='list-group-item web-subhead text-center text-md-start card-title py-3'
              style={{ color: 'white', background: 'rgba(30, 65, 100, 1)', margin: 0 }}
            >
              Multikrd
            </li>
            <div className='card-body'>
              {multikrd.map((text, index) => {
                return (
                  <div key={index} className='card-item'>
                    <div
                      className='text-regular text-center text-md-start'
                      style={{ color: 'white', width: '100%' }}
                    >
                      {text}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const currentMakeProviders = [
  'Siloed financial products and services',
  'Limited cashback promotions',
  'Limited promotions for daily essential savings',
  'Low employee utilization',
  'Limited off-platform functionality',
  'Limited customization',
  'Low or no company partnering opportunities'
]
const multikrd = [
  'Holistic, all-in-one solution combines wage access, tip access, and special offers.',
  'Large promotions program offers 1.2m discounts with 700,000+ retailers ',
  'Multiple daily essential savings',
  'User-friendly tools to source and redeem promotions',
  'Dynamic promotion-finder plugin',
  'Fully customizable modular design',
  'Opportunies to partner with PEOs'
]
