import React from 'react'
import { motion } from 'framer-motion'

import circles from '../../../assets/images/benefits/accordions-circles.svg'
import image from '../../../assets/images/benefits/accordions-image.webp'
import Accordion from '../../../components/accordion/Accordion'
import Footnote from '../../../components/footnote/Footnote'
import Animations from '../../../utils/animations'
import useStyles from '../Benefits.styles'

export default function BenefitsAccordions () {
  const classes = useStyles()

  return (
    <>
      <div className={classes.accordionContainer}>
        <div className={classes.accordionColumn}>
          <div className={classes.accordionEmployersContainer}>
            <h1 className={classes.accordionTitle}>A win-win platform</h1>
            <h3 className={classes.accordionSubtitle}>Explore company-wide benefits at a glance</h3>
            <div className='web-subhead pb-5'>For Employers</div>
            <div className='pb-5 mb-5'>
              <Accordion content={employersAccordion} />
            </div>
          </div>
          <div className={classes.accordionCaptionContainer}>
              <motion.img className={classes.accordionCaptionImage} src={image} alt='Employers' 
                {...Animations.FADE()}
              />
            <div>
              <span className={classes.accordionCaptionAccent}>Drive Business Success </span>
              <span className={classes.accordionCaption}>by Focusing on Employee Happines and Loyalty</span>
            </div>
          </div>
        </div>
        <div className={classes.accordionColumn}>
          <div className={classes.accordionEmployeesContainer}>
              <motion.img src={circles} alt='Employees' className={classes.accordionEmployersImage}
                {...Animations.POP()}
              />
            <div className='web-subhead pb-5'>For Employees</div>
            <div className='pb-5 mb-5'>
              <Accordion content={employeesAccordion} />
            </div>
          </div>
        </div>
      </div>
      <Footnote notes={[
        '(1) Multikrd LLC is a financial technology company and not a FDIC-insured bank. Checking accounts and the Multikrd Visa® Debit Card are provided by Lewis & Clark Bank, Member FDIC. Funds deposited into the checking account may be eligible for up to $250,000 of FDIC insurance. The FDIC’s deposit insurance coverage only protects against the failure of a FDIC-insured depository institution.',
        '(2) Study conducted by The Harris Poll on behalf of Ceridian. August 2021.'
      ]} />
    </>
  )
}

const employersAccordion = [
  {
    title: 'Attract talent',
    textLine1: 'According to a recent Harris survey over 80% of those polled said they would take a job with an employer that offers wages on demand over an employer that does not.⁽²⁾'
  },
  {
    title: 'Keep talent',
    textLine1: 'Employees in that same poll overwhelmingly 79% said earned wage access would make them feel more valued as an employee.⁽²⁾'
  },
  {
    title: 'Lower absenteeism',
    textLine1: 'When employees have control over their pay they are less stressed, more motivated, and more engaged.'
  },
  {
    title: 'Reach HR goals',
    textLine1: 'In-platfrom spot bonuses and gifts are a low-cost way to say “thank you” for a job well done, or to mark a special occasion like a birthday.'
  },
  {
    title: 'Lessen the burden on your HR department',
    textLine1: 'Our platform is easy to set up and easy to run at ZERO COST to you. Remove expenses and administrative burden all at once.'
  }
]

const employeesAccordion = [
  {
    title: 'Financial Control',
    textLine1: 'With access to their paycheck or tips without having to wait for payday, employees have more control over their finances overall.'
  },
  {
    title: 'Peace of mind',
    textLine1: 'As reported in a 2021 Wagestream report, 77% of EWA users felt less stress and 88% reduced reliance on high-cost payday loans.'
  },
  {
    title: 'Safety net',
    textLine1: 'Imagine the relief of being able to cover a large bill or sudden expense without having to pay interest of any kind.'
  },
  {
    title: 'Easy access to banking services',
    textLine1: 'Nearly half of work- class Americans are under-banked because they don\'t meet the requirements to open a bank account.The MK Card and account ⁽¹⁾ are accessible to most Americans, with simple application requirements and a great user experience to manage their funds with ease.'
  },
  {
    title: 'Cashback, again and again',
    textLine1: 'Our MK platform offers cashback at popular retailers, allowing employees to accrue extra cash for a rainy day or special occasion.'
  },
  {
    title: 'Customized coupons and savings',
    textLine1: 'We offer more than a million perks and discounts on groceries, restaurants, clothing, and more.'
  },
  {
    title: 'Travel discounts galore',
    textLine1: 'Cars, vacations, and flights are just the beginning.'
  }
]