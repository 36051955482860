import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  blueTitle: {
    color: '#0097EC',
    fontSize: 64,
    fontWeight: 'bold',
    paddingBottom: 16,
    paddingTop: 56,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      paddingBottom: 8,
      paddingTop: 32,
    }
  },
  financesContainer: {
    display: 'flex',
    marginBottom: 40,
    paddingBottom: 32,
    paddingTop: 32,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: 16,
      paddingBottom: 16,
      paddingTop: 16
    }
  },
  financesImageContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100%'
  },
  financesImage: {
    width: '100%'
  },
  financesShape: {
    bottom: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: -1,
    [theme.breakpoints.down('xs')]: {
      top: 16,
      width: '80%'
    }
  },
  financesSubtitle: {
    fontFamily: 'Work Sans',
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    }
  },
  financesTitle: {
    fontFamily: 'Work Sans',
    fontSize: 70,
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
    }
  },
  financesTitleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingBottom: 16,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 16,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      paddingTop: 40
    }
  },
  headerContainer: {
    marginBottom: 32,
    position: 'relative'
  },
  headerFigure: {
    bottom: 0,
    right: 0,
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    }
  },
  headerImage: {
    cursor: 'pointer',
    width: '90%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  headerNotification: {
    left: 0,
    margin: '0 auto',
    position: 'absolute',
    right: 0,
    top: 56,
    [theme.breakpoints.down('md')]: {
      top: 16,
      width: '40%'
    }
  },
  servicesContainer: {
    backgroundColor: '#004598',
    color: '#CFEEFA',
    padding: 40,
    [theme.breakpoints.down('md')]: {
      padding: 16
    }
  },
  servicesItemsContainer: {
    alignItems: 'start',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyItems: 'center',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    }
  },
  servicesItem: {
    padding: 40,
    width: '70%',
    [theme.breakpoints.down('md')]: {
      padding: 16,
      width: '80%'
    }
  },
  servicesItemDescription: {
    fontSize: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }
  },
  servicesItemTitle: {
    fontSize: 32,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 24
    }
  },
  servicesTitle: { 
    color: 'white',
    fontSize: 40,
    fontWeight: 'bold',
    marginTop: 16,
    [theme.breakpoints.down('md')]: {
      fontSize: 32
    }
  }
}), { classNamePrefix: 'Card' })