import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Button, TextField } from '@material-ui/core'
import { motion } from 'framer-motion'

import { posts } from '../../assets/content/Blog/Posts'
import SearchIcon from '../../assets/icons/search.png'
import Forward from '../../assets/icons/forward.png'
import Animations from '../../utils/animations'

export default function Blog () {
  const { id } = useParams()

  const [postOpen, setPostOpen] = useState()
  const [postValue, setPostValue] = useState()

  useEffect(() => {
    if (id !== undefined) {
      setPostOpen(id)
    }
  }, [id])

  const goPost = index => {
    setPostOpen(index)
    window.scrollTo(0, 0)
  }

  const defaultProps = {
    options: posts,
    getOptionLabel: option => option.title
  }

  return (
    <div className='px-5 py-5 row'>
      {postOpen === undefined
        ? (
          <div className='col-12 col-md-9 pb-5'>
            <div className='row'>
              <div className='web-header pb-5'>Blog</div>
              <img src={posts[0].img} alt='blog-header' style={{ width: '95%' }} />
              <div className='medium pt-5 text-start'>{posts[0].title}</div>
              <div className='text-regular text-start pb-5 pt-2 d-flex'>
                <div>{'Posted on: ' + posts[0].date}</div>
                <div className='ps-3'>
                  {'By: '}
                  <a href='/'>{posts[0].author}</a>
                </div>
              </div>
              <div className='text-regular text-start pb-5'>
                {posts[0].resume}
                <Button onClick={() => goPost(0)} color='primary'>
                  Read More
                </Button>
              </div>
            </div>
            <div className='row'>
              {posts.map((e, index) => {
                if (index !== 0) {
                  const delay = index % 2 === 0 ? 0.3 : 0

                  return (
                    <div
                      key={index}
                      className='col-12 col-sm-6 p-1 pb-5 p-md-5'
                      style={{ textAlign: 'left' }}
                    >
                      <div>
                        <motion.img src={e.img} alt='video-img' width='100%' {...Animations.FADE({ delay })} />
                      </div>
                      <div className='medium pt-4 text-start'>{e.title}</div>
                      <div className='text-regular pb-4'>{e.date}</div>
                      <div className='text-regular pb-3'>{e.resume}</div>
                      <Button onClick={() => goPost(index)} color='primary'>
                        Read More
                      </Button>
                    </div>
                  )
                }
                return ''
              })}
            </div>
          </div>
          )
        : (
          <div className='col-12 col-md-9 pb-5 mb-5'>
            <div className='large-text pb-5'>
              <Button
                onClick={() => {
                  setPostValue('')
                  setPostOpen()
                }}
                className='me-3'
              >
                <img src={Forward} alt='return' />
              </Button>
              Blog
            </div>
            <img src={posts[postOpen].imgContent} alt='blog-header' className='pe-5' width='100%' />
            <div className='title-post pt-5 text-start' style={{ width: '80%', textAlign: 'left' }}>
              {posts[postOpen].title}
            </div>
            <div className='text-regular text-start py-5 d-flex'>
              <div>{'Posted on: ' + posts[postOpen].date}</div>
              <div className='ps-3'>
                {'By: '}
                <a href='#title'>{posts[postOpen].author}</a>
              </div>
            </div>
            {posts[postOpen].content}
          </div>
          )}
      <div className='col-12 col-md-3 ps-5 pt-5 px-5 p-md-0'>
        <Autocomplete
          {...defaultProps}
          value={postValue}
          onChange={(event, newValue) => {
            setPostValue(newValue)
            posts.map((e, index) => {
              if (e.title === newValue.title) {
                return goPost(index)
              } else return ''
            })
          }}
          renderInput={params => (
            <TextField
              label='Search'
              variant='filled'
              className='pb-5 w-100'
              InputProps={{
                endAdornment: <img src={SearchIcon} alt='search' />
              }}
              {...params}
              margin='normal'
            />
          )}
        />
        <div className='large-text pb-4' style={{ fontWeight: '600' }}>
          Recent Posts
        </div>
        {posts.map((e, index) => {
          if (index < 6) {
            return (
              <div key={index} className='blog-link-container py-4 d-flex '>
                <Button onClick={() => goPost(index)}>
                  <div className='link-app'>{e.title}</div>
                </Button>
              </div>
            )
          } else {
            return ''
          }
        })}
      </div>
    </div>
  )
}
