import React from 'react'
import { useNavigate } from 'react-router-dom'

import image from '../../../assets/images/cashback/header.webp'
import figure from '../../../assets/images/shapes/red-bottom-left.svg'
import Paths from '../../../utils/paths'
import useStyles from '../Cashback.styles'

export default function CashbackHeader () {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.headerContainer} onClick={() => navigate(Paths.CONTACT)}>
      <img src={figure} alt='header-shape' className={classes.headerFigure} />
      <img src={image} alt='header-image' className={classes.headerImage} />
    </div>
  )
}