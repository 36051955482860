import React from 'react'

import CheckIcon from '../../../assets/icons/check.svg'
import Footnote from '../../../components/footnote/Footnote'

export default function HowItWorksServices() {
  return (
    <div className='d-flex flex-column our-service-container'>
      <div className='web-header text-center py-5 my-5'>Our services⁽¹⁾</div>
      <div className='row ps-5 pb-5 mb-5'>
        <div className='col-12 col-sm-6 d-flex flex-column ps-1 ps-lg-5'>
          <div className='list-header text-start pb-3'>For employers:</div>
          {ourServiceEmployers.map((text, index) => {
            return (
              <div key={index} className='text-start list-item py-4'>
                <img className='icon-list me-4' src={CheckIcon} alt='check' />
                {text}
              </div>
            )
          })}
        </div>
        <div className='col-12 col-sm-6 d-flex flex-column ps-1 ps-lg-5'>
          <div className='list-header text-start pb-3'>For employees:</div>
          {ourServiceemployees.map((text, index) => {
            return (
              <div key={index} className='text-start list-item py-4'>
                <img className='icon-list me-4' src={CheckIcon} alt='check' />
                {text}
              </div>
            )
          })}
        </div>
      </div>
      <Footnote notes={[
        '(1) Subject to terms and conditions',
        '(2) Multikrd LLC is a financial technology company and not a FDIC-insured bank. Checking accounts and the Multikrd Visa® Debit Card are provided by Lewis & Clark Bank, Member FDIC. Funds deposited into the checking account may be eligible for up to $250,000 of FDIC insurance. The FDIC’s deposit insurance coverage only protects against the failure of a FDIC-insured depository institution.'
      ]} />
    </div>
  )
}

const ourServiceEmployers = [
  'Rewards',
  'Incentives',
  'Communication tools',
  'Reporting tools',
  'Web and mobile apps (iOS & Android)',
  'PEO customized integrations',
  'Modular design and customization'
]

const ourServiceemployees = [
  'Up to 100% earned wage and tip access',
  '1.2m promotions',
  '700,000+ merchant partnerships',
  'Daily essential savings',
  'Easy access to banking services⁽²⁾',
  'Promotions finder mobile geo-localization'
]
