import React from 'react'

import slide1 from '../../../assets/images/travel/slide-1.webp'
import CheckIcon from '../../../assets/icons/check.svg'
import SlideImage from '../../../components/slide-image/SlideImage'

export default function TravelServices() {
  return (
    <>
      <h2>Everything you need for your next adventure - in one place!</h2>
      <SlideImage
        button={false}
        title=''
        text={
          <div className='d-flex flex-column'>
            {ourServiceEmployers.map((text, index) => {
              return (
                <div key={index} className='text-start list-item py-4'>
                  <img className='icon-list me-4' src={CheckIcon} alt='check' />
                  {text}
                </div>
              )
            })}
            <h4 style={{ color: '#004598', fontWeight: 'bold', textAlign: 'center', paddingTop: 8}}>and much more!</h4>
          </div>
        }
        image={slide1}
      />
    </>
  )
}

const ourServiceEmployers = [
  'Hotels and Resorts',
  'Car Rentals',
  'Theme Park Tickets',
  'Flights',
  'Cruise Lines',
  'Activities'
]
