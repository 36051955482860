import React from 'react'
import { MediaDocuments, MediaVideos } from './components'

export default function Media () {
  return (
    <div className='px-2 px-sm-5 pt-5'>
      <MediaVideos />
      <MediaDocuments />
    </div>
  )
}
