import React from 'react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import banking from '../../../assets/images/benefits/services-banking.svg'
import cashback from '../../../assets/images/benefits/services-cashback.svg'
import discounts from '../../../assets/images/benefits/services-discounts.svg'
import rewards from '../../../assets/images/benefits/services-rewards.svg'
import tips from '../../../assets/images/benefits/services-tips.svg'
import wageAccess from '../../../assets/images/benefits/services-wage-access.svg'
import paths from '../../../utils/paths'

export default function BenefitsServices () {
  const navigate = useNavigate()

  const hoverAnimation = {
    scale: 1.1,
    transition: { duration: 0.2 },
  }

  return (
    <div className='benefits-icons py-5 d-flex flex-column'>
      <div className='web-header d-flex justify-content-center pb-5 '>
        <div className='benefits-icons-title pb-5 pt-5'>
          Be a business where good things happen all the time.
        </div>
      </div>
      <div className='row py-5 px-5 px-lg-1'>
        <div className='col-6 col-md-4 pb-5 mb-5'>
          <motion.img src={wageAccess} whileHover={hoverAnimation} alt='icon1' className='benefits-icons-icon' onClick={() => navigate(paths.WAGE_ACCESS)} />
          <div className='medium pt-3'>Earned wage access</div>
        </div>
        <div className='col-6 col-md-4 pb-5 mb-5'>
          <motion.img src={discounts} whileHover={hoverAnimation} alt='icon2' className='benefits-icons-icon' onClick={() => navigate(paths.DISCOUNTS)} />
          <div className='medium pt-3'>Discounts</div>
        </div>
        <div className='col-6 col-md-4 pb-5 mb-5'>
          <motion.img src={cashback} whileHover={hoverAnimation} alt='icon3' className='benefits-icons-icon' onClick={() => navigate(paths.CASHBACK)} />
          <div className='medium pt-3'>Cashback</div>
        </div>
        <div className='col-6 col-md-4 pb-5'>
          <motion.img src={tips} whileHover={hoverAnimation} alt='icon4' className='benefits-icons-icon' onClick={() => navigate(paths.TIPS)} />
          <div className='medium pt-3'>Earned tip access</div>
        </div>
        <div className='col-6 col-md-4 pb-5'>
          <motion.img src={banking} whileHover={hoverAnimation} alt='icon5' className='benefits-icons-icon' onClick={() => navigate(paths.CARD)} />
          <div className='medium pt-3'>MK easy banking access⁽³⁾</div>
        </div>
        <div className='col-6 col-md-4 pb-5'>
          <motion.img src={rewards} whileHover={hoverAnimation} alt='icon6' className='benefits-icons-icon' onClick={() => navigate(paths.TRAVEL)} />
          <div className='medium p-3'>Special rewards</div>
        </div>
      </div>
      <div className='row px-3 pb-5'>
        <div className='col-6 px-5' />
        <div className='px-5 pt-5 employees-subtext'>
          (3) Multikrd LLC is a financial technology company and not a FDIC-insured bank. Checking accounts and the Multikrd Visa® Debit Card are provided by Lewis & Clark Bank, Member FDIC. Funds deposited into the checking account may be eligible for up to $250,000 of FDIC insurance. The FDIC’s deposit insurance coverage only protects against the failure of a FDIC-insured depository institution.
        </div>
      </div>
    </div>
  )
}