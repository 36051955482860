import Img24 from './post-2.jpg'
import Img23 from './post-3.jpg'
import Img22 from './post-4.jpg'
import Img21 from './post-5.jpg'
import Img20 from './post-6.jpg'
import Img19 from './post-7.jpg'
import Img18 from './post-8.jpg'
import Img17 from './post-9.jpg'
import Img16 from './post-10.jpg'
import Img15 from './post-11.jpg'
import Img14 from './post-12.jpg'
import Img13 from './post-13.jpg'
import Img12 from './post-14.jpg'
import Img11 from './post-15.jpg'
import Img10 from './post-16.jpg'
import Img9 from './post-17.jpg'
import Img8 from './post-18.jpg'
import Img7 from './post-19.jpg'
import Img6 from './post-20.jpg'
import Img5 from './post-21.jpg'
import Img4 from './post-22.jpg'
import Img3 from './post-23.jpg'
import Img2 from './post-24.jpg'
import Img1 from './post-25.jpg'
import Img26 from './post-26.jpg'
import Img28 from './post-28.jpg'
import Img29 from './post-29.jpg'
import Img30 from './post-30.jpg'
import Img32 from './post-32.png'
import Img33 from './post-33.png'
import Img34 from './post-34.png'
import Img35 from './post-35.png'
import Img39 from './post-39.png'
import Img42 from './post-42.png'
import Img44 from './post-44.png'
import Img45 from './post-45.png'
import Img46 from './post-46.png'
import Img47 from './post-47.png'
import Img48 from './post-48.png'
import Img49 from './post-49.png'
import Img50 from './post-50.png'
import Img51 from './post-51.png'
import Img52 from './post-52.png'
import Img53 from './post-53.png'
import Img54 from './post-54.png'

const Blog = {
  IMG_24: Img24,
  IMG_23: Img23,
  IMG_22: Img22,
  IMG_21: Img21,
  IMG_20: Img20,
  IMG_19: Img19,
  IMG_18: Img18,
  IMG_17: Img17,
  IMG_16: Img16,
  IMG_15: Img15,
  IMG_14: Img14,
  IMG_13: Img13,
  IMG_12: Img12,
  IMG_11: Img11,
  IMG_10: Img10,
  IMG_9: Img9,
  IMG_8: Img8,
  IMG_7: Img7,
  IMG_6: Img6,
  IMG_5: Img5,
  IMG_4: Img4,
  IMG_3: Img3,
  IMG_2: Img2,
  IMG_1: Img1,
  IMG_26: Img26,
  IMG_28: Img28,
  IMG_29: Img29,
  IMG_30: Img30,
  IMG_32: Img32,
  IMG_33: Img33,
  IMG_34: Img34,
  IMG_35: Img35,
  IMG_39: Img39,
  IMG_42: Img42,
  IMG_44: Img44,
  IMG_45: Img45,
  IMG_46: Img46,
  IMG_47: Img47,
  IMG_48: Img48,
  IMG_49: Img49,
  IMG_50: Img50,
  IMG_51: Img51,
  IMG_52: Img52,
  IMG_53: Img53,
  IMG_54: Img54
}

export default Blog