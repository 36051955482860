import React, { useRef } from 'react'

import Footnote from '../../components/footnote/Footnote'
import SlideImage from '../../components/slide-image/SlideImage'
import RedCardBlock from '../../components/red-card-block/RedCardBlock'
import SlideHeader from '../../components/slide-header/SlideHeader'
import SlideCarousel from '../../components/SlideCarousel'
import headerImage from '../../assets/images/employees/header.webp'
import headerFigure from '../../assets/images/shapes/red-bottom-left.svg'
import CarouselImg1 from '../../assets/images/carrousel/employees-1.png'
import CarouselImg2 from '../../assets/images/carrousel/employees-2.png'
import CarouselImg3 from '../../assets/images/carrousel/employees-3.png'
import descriptionImage from '../../assets/images/home/description.svg'
import savingsImage from '../../assets/images/employees/savings.svg'
import cashbackImage from '../../assets/images/employees/cashback.svg'
import paths from '../../utils/paths'
import { EmployeesCard } from './components'
import AnimatedDollars from '../../components/animated-dollars/AnimatedDollars'
import AnimatedLabel from '../../components/animated-label/AnimatedLabel'
import AnimatedCoin from '../../components/animated-coin/AnimatedCoin'

export default function Employees () {
  const footnoteRef = useRef(null)

  const handleFootnoteClick = () => {
    footnoteRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div>
      <SlideHeader
        textPosition='right'
        image={headerImage}
        title='Reap rewards, gain financial control, and save every day'
        text='Getting paid on your terms is just the beginning.'
        figure={headerFigure}
        figureClass='figure-header-employees'
        buttonText='Get in touch'
        buttonColor='black'
      />
      <EmployeesCard onClick={handleFootnoteClick} />
      <SlideImage
        title={
          <div className='web-header d-flex flex-column '>
            <div className='web-subhead'>Quick access to funds:</div>It’s your money. You’ve earned
            it.
          </div>
        }
        text={
          <div>
            <p>MK <b>connects directly to your employer’s payroll</b> so you have timely access to a portion of the money you’ve already earned. You can even set your own payday schedule. And unlike payday lenders, we don’t charge heavy interest rates or damage your credit.
              <span
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={handleFootnoteClick}
              >
                ⁽²⁾
              </span>
            </p>
            <p>For hospitality industry workers, ask your employer to connect and get instant access to your earned tips!</p>
          </div>
        }
        animation={<AnimatedDollars />}
        image={descriptionImage}
        buttonText='Learn more about EWA'
        buttonLink={paths.WAGE_ACCESS}
        secondaryButtonText='Learn more about Tips On-Demand'
        secondaryButtonLink={paths.TIPS}
      />
      <SlideImage
        animation={<AnimatedLabel />}
        title='Personalized savings— anytime anywhere'
        text={<p>We use smart recommendations and location-based alerts to send you relevant and timely promotions—both online and in-store. <b>Save money while you shop</b>, whether you’re eating out, buying basics, or planning a trip.</p>}
        image={savingsImage}
        textPosition='left'
        buttonText='Learn more about Discounts'
        buttonLink={paths.DISCOUNTS}
        secondaryButtonText='Learn more about Travel'
        secondaryButtonLink={paths.TRAVEL}
      />
      <SlideImage
        animation={<AnimatedCoin />}
        title='Get real cashback'
        text={<p>Earn <b>up to 20% cashback on daily essentials</b>—at stores where you already shop. National brands are part of our program. You can receive your cashback to your MK card or through a no-fee transfer to another account of your choice.</p>}
        image={cashbackImage}
        buttonText='Learn more about Cashback'
        buttonLink={paths.CASHBACK}
      />
      <div ref={footnoteRef}>
        <Footnote
          notes={[
            '(1) Multikrd LLC is a financial technology company and not a FDIC-insured bank. Checking accounts and the Multikrd Visa® Debit Card are provided by Lewis & Clark Bank, Member FDIC. Funds deposited into the checking account may be eligible for up to $250,000 of FDIC insurance. The FDIC’s deposit insurance coverage only protects against the failure of a FDIC-insured depository institution.',
            '(2) Subject to terms and conditions.',
            '(3) Offers shown are displayed for advertising purposes only, real discounts may vary.',
            '(4) Companies and brands that reward with cashback may vary from time to time, these are shown for advertising purposes only.'
          ]}
        />
      </div>
      <SlideCarousel items={carouselItems} />
      <RedCardBlock title='Interested in having all these benefits?' buttonText='Leave us a message' subtitle='and don’t forget to tell your employer that they are missing out!' />
    </div>
  )
}

const carouselItems = [
  {
    title: '“Now I can make online payments and get cash at any ATM”',
    text: ' I never had a bank account until I started this job. My employer offered this solution and I immediately requested a card. Now I can make online payments and get cash at any ATM. ',
    autor: '- Jonathan T., Electrician',
    img: CarouselImg1,
    design: 3
  },
  {
    title: 'How I saved over $500',
    text: 'Last time I traveled with my husband and two kids, we booked through my app and we not only saved on hotels, but also purchased discounted theme park tickets, AND got cashback! ',
    autor: '- Jessica S., Speech Pathologist',
    header: 'USER STORY:',
    img: CarouselImg2,
    design: 2
  },
  {
    title: '“I love the Auto Wage Access feature”',
    text: 'I use my employee portal to look at my account balance, transfer money, make purchases, and request my earned wages. I love the Auto Wage Access feature. I know that every Monday I will be receiving what I want to spend that week.',
    autor: '- Caroline M., Receptionist',
    img: CarouselImg3,
    design: 1
  }
]
