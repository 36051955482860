import { Navigate, Routes, Route } from 'react-router-dom'
import Home from './pages/home/Home'
import MetaTags from './components/MetaTags'
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import AboutUs from './pages/about-us/AboutUs'
import Benefits from './pages/benefits/Benefits'
import Blog from './pages/blog/Blog'
import Card from './pages/card/Card'
import Cashback from './pages/cashback/Cashback'
import Contact from './pages/Contact'
import ContactOut from './pages/ContactOut'
import Customers from './pages/Customers'
import Discounts from './pages/discounts/Discounts'
import Employees from './pages/employees/Employees'
import FAQ from './pages/faq/FAQ'
import HowItWorks from './pages/how-it-works/HowItWoks'
import Media from './pages/media/Media'
import Signup from './pages/Signup'
import Tips from './pages/tips/Tips'
import Travel from './pages/travel/Travel'
import WageAccess from './pages/wage-access/WageAccess'
import ScrollToTop from './utils/ScrollTop'
import TermsAndConditions from './pages/T&C.js'
import { checkB2CSubdomain } from './utils/helpers'
import Paths from './utils/paths'
import { B2C_FEATURE_ACTIVE } from './utils/constants'

if (!B2C_FEATURE_ACTIVE) {
  const currentDomain = window.location.hostname.split('.')[0]
  const BASE_DOMAIN = 'multikrd'
  const DEV_DOMAINS = ['localhost', 'test', 'landing']
  if (currentDomain !== BASE_DOMAIN && !DEV_DOMAINS.includes(currentDomain)) {
    const baseURL = window.location.origin.replace(`${currentDomain}.`, '')
    window.location.replace(baseURL)
  }
}

function App () {
  const isB2C = B2C_FEATURE_ACTIVE ? checkB2CSubdomain() : false
  
  return (
    <div className='App'>
      <MetaTags />
      <ScrollToTop>
        <Navbar fixed />
        <Routes>
          <Route path={Paths.ROOT} element={isB2C ? <Customers /> : <Home />} />
          <Route path={Paths.HOME} element={<Home />} />
          <Route path={Paths.ABOUT_US} element={<AboutUs />} />
          <Route path={Paths.BENEFITS} element={<Benefits />} />
          <Route path={Paths.BLOG + '/:id'} element={<Blog />} />
          <Route path={Paths.BLOG} element={<Blog />} />
          <Route path={Paths.CARD} element={<Card />} />
          <Route path={Paths.CASHBACK} element={<Cashback />} />
          <Route path={Paths.CONTACT_US} element={<ContactOut />} />
          <Route path={Paths.CONTACT} element={<Contact />} />
          <Route path={Paths.DISCOUNTS} element={<Discounts />} />
          <Route path={Paths.EMPLOYEES} element={<Employees />} />
          <Route path={Paths.FAQS} element={<FAQ />} />
          <Route path={Paths.HOW_IT_WORKS} element={<HowItWorks />} />
          <Route path={Paths.LEGAL_AGREEMENTS} element={<TermsAndConditions />} />
          <Route path={Paths.MEDIA} element={<Media />} />
          <Route path={Paths.TIPS} element={<Tips />} />
          <Route path={Paths.TRAVEL} element={<Travel />} />
          <Route path={Paths.WAGE_ACCESS} element={<WageAccess />} />
          {B2C_FEATURE_ACTIVE && (
            <>
              <Route path={Paths.CUSTOMERS} element={<Customers />} />
              <Route path={Paths.SIGNUP} element={<Signup />} />
            </>
          )}
          <Route path="*" element={<Navigate to={Paths.ROOT} />} />
        </Routes>
        <Footer />
      </ScrollToTop>
    </div>
  )
}

export default App
