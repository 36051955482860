import React from 'react'
import { Button } from '@material-ui/core'
import { motion } from 'framer-motion'
import { InsertDriveFileOutlined } from '@material-ui/icons'

import ImgDocument from '../../../assets/images/documents/documents-1.png'
import { compliance, flyer } from '../../../assets/images/documents'

export default function MediaDocuments () {
  const containerVariants = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    show: { opacity: 1, y: 0 }
  }

  return (
    <>
      <div className='row p-5 mt-5'>
        <div className='web-header'>Documents</div>
      </div>
      <motion.div className='row justify-content-center'
        variants={containerVariants}
        initial='hidden'
        whileInView='show'>
        {documents.map((e, index) => {
          return (
            <motion.div key={`document-${index}`} style={{width: '50%'}} variants={itemVariants} transition={{ duration: 0.3 }}>
              <div className='col p-5 d-table d-md-flex'>
                <Button
                  onClick={() => {
                    const createA = document.createElement('a')
                    createA.setAttribute('href', e.link)
                    createA.setAttribute('target', '_blank')
                    createA.click()
                  }}
                  style={{ position: 'relative' }}
                  >
                  <img
                    className='w-100'
                    src={e.img !== undefined ? e.img : ImgDocument}
                    alt='document'
                    style={{ right: '0px' }}
                    />
                  <div style={{ backgroundColor: '#0097EC', padding: 8, color: 'white', position: 'absolute', bottom: 0, right: 0 }}>
                    <InsertDriveFileOutlined color='inherit' />
                  </div>
                  {/* <img src={IconDocument} alt='icon-document' className='icon-document' /> */}
                </Button>
                <div className='d-flex flex-column ps-4 ms-4 border-top'>
                  <div className='medium pt-2 text-start'>{e.title}</div>
                  <div className='text-regular text-start'>{e.date}</div>
                </div>
              </div>
              {(index + 1) % 2 === 0 && <div class='w-100' />}
              {index + 1 === documents.length && <div class='w-50' />}
            </motion.div>
          )
        })}
      </motion.div>
    </>
  )
}

const documents = [
  // {
  //   title: 'Multikrd Presentation',
  //   link: 'https://s3-landing-page-content.s3.amazonaws.com/Multikrd+Sales+Presentation.pdf',
  //   img: presentation
  // },
  {
    title: 'Multikrd Flyer',
    link: 'https://s3-landing-page-content.s3.amazonaws.com/Multikrd+One-Pager.pdf',
    img: flyer
  },
  {
    title: 'Multikrd Compliance Guide',
    link: 'https://s3-landing-page-content.s3.amazonaws.com/MKComplianceGuide.pdf',
    img: compliance
  }
]
