import React from 'react'

import image from '../../../assets/images/home/description.svg'
import SlideImage from '../../../components/slide-image/SlideImage'
import AnimatedDollars from '../../../components/animated-dollars/AnimatedDollars'
import Paths from '../../../utils/paths'

export default function HomeDescription () {
  return (
    <div style={{position: 'relative'}}>
      <SlideImage
        animation={<AnimatedDollars />}
        title='A win-win platform'
        text={
          <div>
            <p>Multikrd is a unique platform with  Earned Wage Access, cashback savings and customized discounts that also provides access to a bank account with direct deposit and a Debit Card⁽¹⁾ in a single platform.</p>
            <p>With Multikrd, employees <b>get money when they need it, save when they shop,</b> and <b>build financial security.</b></p>
          </div>
        }
        image={image}
        buttonLink={Paths.HOW_IT_WORKS}
      />
    </div>
  )
}