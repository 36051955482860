import React from 'react'

export default function FooterSocial() {
  return (
    <>
      <div style={{ fontSize: 12, marginBottom: 8 }} className='col d-flex justify-content-start align-items-center footer-text-1 px-5'>
        ©{new Date().getFullYear()} Multikrd LLC. All rights reserved
      </div>
      <div style={{ fontSize: 12, textAlign: 'left' }} className='col d-flex justify-content-start align-items-center footer-text-1 px-5 pb-3'>
        Multikrd LLC is a financial technology company and not a FDIC-insured
        bank. Checking accounts and the Multikrd Visa® Debit Card are provided
        by Lewis & Clark Bank, Member FDIC. Funds deposited into the checking
        account may be eligible for up to $250,000 of FDIC insurance. The FDIC’s
        deposit insurance coverage only protects against the failure of a FDIC-insured
        depository institution.
      </div>
    </>
  )
}