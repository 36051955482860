import React from 'react'
import { motion } from 'framer-motion'

import SlideImage from '../../components/slide-image/SlideImage'
import customerSuccess from '../../assets/images/how-it-works/customer-success.jpg'
import servicesImage1 from '../../assets/images/how-it-works/services-line-1.jpg'
import servicesImage2 from '../../assets/images/how-it-works/services-line-2.jpg'
import servicesImage3 from '../../assets/images/how-it-works/services-line-3.jpg'
import servicesImage4 from '../../assets/images/how-it-works/services-line-4.jpg'
import Navbar from '../../components/navbar/Navbar' 
import RedCardBlock from '../../components/red-card-block/RedCardBlock'
import Animations from '../../utils/animations'
import { HowItWorksComparison, HowItWorksIncentives, HowItWorksIntegration, HowItWorksServices, HowItWorksSteps } from './components'

export default function HowItWorks () {
  return (
    <>
      <Navbar super />
      <HowItWorksIncentives />
      <HowItWorksIntegration />
      <HowItWorksSteps />
      <SlideImage
        button={false}
        textPosition='left'
        title='An extra plus'
        text={<div>
          Our platform strengthens workplaces while rewarding employees. <b>Our Customer Success</b> team will make sure that Multikrd is aligned to your company goals and customize special promotions that boost productivity among your workforce and increases engagement, making the program a personal experience.
        </div>}
        image={customerSuccess}
      />
      <HowItWorksComparison />
      <div className='row pt-5'>
        <motion.img className='col-3' src={servicesImage1} alt='servicesImage1' {...Animations.FADE({ duration: 0.3, delay: 0.2 })} />
        <motion.img className='col-3' src={servicesImage2} alt='servicesImage1' {...Animations.FADE({ duration: 0.3, delay: 0.4 })} />
        <motion.img className='col-3' src={servicesImage3} alt='servicesImage1' {...Animations.FADE({ duration: 0.3, delay: 0.6 })} />
        <motion.img className='col-3' src={servicesImage4} alt='servicesImage1' {...Animations.FADE({ duration: 0.3, delay: 0.8 })} />
      </div>
      <HowItWorksServices />
      <RedCardBlock title='Ready to get started?' buttonText='Get in touch!' />
    </>
  )
}
