import React from 'react'
import { Button } from '@material-ui/core'

import useStyles from '../Media.styles'

export default function MediaItem ({ index, item, setOpen, setVideo }) {
  const classes = useStyles()

  return (
    <div className='row pb-5 mb-5'>
      <div
        className='col-12 col-md-4 p-3 p-lg-5 order-2 order-md-1'
        style={{ background: '#FDBA63', textAlign: 'left' }}
      >
        <div className='medium'>{item.title}</div>
        <div className='text-regular pb-5'>{item.date}</div>
        <div className='text-regular pb-5'>{item.description}</div>
        <Button
          variant='outlined'
          className={`button-media ${classes.buttonBlack}`}
          onClick={() => {
            setVideo(index)
            setOpen(true)
          }}
        >
          Watch Video
        </Button>
      </div>
      <div className='col-12 col-md-8 order-1 order-md-2'>
        <img src={item.image} alt='img-video' width='100%' />
      </div>
    </div>
  )
}