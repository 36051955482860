import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, SwipeableDrawer } from '@material-ui/core'
import { Menu } from '@material-ui/icons'

import Logo from '../../assets/images/logo.webp'
import { NavbarButtons, NavbarSideMenu } from './components'
import { B2C_FEATURE_ACTIVE } from '../../utils/constants'
import { checkB2CSubdomain } from '../../utils/helpers'
import Paths from '../../utils/paths'
import MenuListComponent from '../MenuList'

const currentDomain = window.location.hostname.split('.')[0]
const MULTIKRD_B2C_SUBDOMAIN = currentDomain === 'multikrd' ? 'consumer' : 'consumer-test'

export default function Navbar (props) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [local, setLocal] = useState('')
  const location = useLocation()

  const isB2C = checkB2CSubdomain()

  useEffect(() => {
    if (
      location.pathname.slice(1) === 'how-it-works' ||
      location.pathname.slice(1) === 'benefits'
    ) {
      setLocal('For Companies')
    } else if (
      location.pathname.slice(1) === 'media' ||
      location.pathname.slice(1) === 'FAQs' ||
      location.pathname.slice(1) === 'blog'
    ) {
      setLocal('Resources')
    } else {
      setLocal(location.pathname.slice(1))
    }
  }, [location.pathname])

  const onClose = () => {
    setOpen(false)
  }

  const handleClickItem = (local, path) => {
    setLocal(local)
    navigate(path)
  }

  if (location.pathname.slice(1) === 'how-it-works' && props.super === undefined) {
    return ''
  } else if (location.pathname.slice(1) === 'contact-us') return ''
  else {
    return (
      <div className='row py-3 navbar-fixed bg-navbar-hiw'>
        <div className='col-1 col-xl-3 d-flex justify-content-start px-4 px-xl-5'>
          <Button onClick={() => navigate(Paths.HOME)}>
            <img alt='Logo' src={Logo} style={{ height: 48 }} />
          </Button>
        </div>
        <div className='justify-content-evenly col-7 col-lg-8 col-xl-6 d-md-flex d-none ps-5 ps-lg-1'>
          <MenuListComponent
            local={local}
            setLocal={setLocal}
            title='For Companies'
            list={[
              { name: 'Benefits', link: Paths.BENEFITS },
              { name: 'How it works', link: Paths.HOW_IT_WORKS }
            ]}
          />
          <MenuListComponent
            local={local}
            setLocal={setLocal}
            title='For Employees'
            list={[
              { name: 'Benefits', link: Paths.EMPLOYEES },
              { name: 'Early Wage Access', link: Paths.WAGE_ACCESS },
              { name: 'Tips On-Demand', link: Paths.TIPS },
              { name: 'The MK Card', link: Paths.CARD },
              { name: 'Discounts', link: Paths.DISCOUNTS },
              { name: 'Travel', link: Paths.TRAVEL },
              { name: 'Cashback', link: Paths.CASHBACK }
            ]}
          />
          {B2C_FEATURE_ACTIVE && (
            <Button
              onClick={() => {
                if (isB2C) {
                  handleClickItem('customers', Paths.CUSTOMERS)
                } else {
                  const consumerURL = window.location.origin.replace(
                    window.location.hostname,
                    `${MULTIKRD_B2C_SUBDOMAIN}.${window.location.hostname}`
                  )
                  if (!window.location.origin.includes(MULTIKRD_B2C_SUBDOMAIN)) {
                    window.location.replace(consumerURL)
                  }
                }
              }}
              className={'button ' + (local === 'customers' ? 'button-active' : '')}
            >
              For direct users
            </Button>
          )}
          <Button
            onClick={() => {
              handleClickItem('about-us', Paths.ABOUT_US)
            }}
            className={'button ' + (local === 'about-us' ? 'button-active' : '')}
          >
            About us
          </Button>
          <MenuListComponent
            local={local}
            setLocal={setLocal}
            title='Resources'
            list={[
              { name: 'Blog', link: Paths.BLOG },
              { name: 'Media Center', link: Paths.MEDIA },
              { name: 'FAQs', link: Paths.FAQS }
            ]}
          />
        </div>
        <div className='col d-flex d-md-inline justify-content-end'>
          <NavbarButtons pathname={location.pathname} onClick={handleClickItem} onClose={onClose} />
          <Button
            variant='outlined'
            onClick={() => setOpen(!open)}
            className='d-inline d-md-none'
            style={{ border: '0px' }}
          >
            <Menu />
          </Button>
        </div>

        <SwipeableDrawer
          anchor='right'
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <NavbarSideMenu isB2C={isB2C} onClickItem={handleClickItem} onClose={onClose} />
        </SwipeableDrawer>
      </div>
    )
  }
}
