import React from 'react'

import Footnote from '../../components/footnote/Footnote'
import RedCardBlock from '../../components/red-card-block/RedCardBlock'
import { CardFinances, CardHeader, CardServices } from './components'
import useStyles from './Card.styles'

export default function WageAccess () {
  const classes = useStyles()

  return (
    <div>
      <CardHeader />
      <CardFinances />
      <CardServices />
      <div>
        <h1 className={classes.blueTitle}>Unlock your financial potential</h1>
        <Footnote notes={[
          '(1) Multikrd LLC is a financial technology company and not a FDIC-insured bank. Checking accounts and the Multikrd Visa® Debit Card are provided by Lewis & Clark Bank, Member FDIC. Funds deposited into the checking account may be eligible for up to $250,000 of FDIC insurance. The FDIC’s deposit insurance coverage only protects against the failure of a FDIC-insured depository institution.',
          '(2) Subject to Terms & Conditions.'
        ]} />
      </div>
      <RedCardBlock title='Get yours today!' buttonText='Create an Account' goToPlatform />
    </div>
  )
}
