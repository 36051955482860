import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { motion } from 'framer-motion'

import image1 from '../../../assets/images/travel/example-1.jpg'
import image2 from '../../../assets/images/travel/example-2.jpg'
import image3 from '../../../assets/images/travel/example-3.jpg'
import image4 from '../../../assets/images/travel/example-4.jpg'
import image5 from '../../../assets/images/travel/example-5.jpg'
import image6 from '../../../assets/images/travel/example-6.jpg'
import plane from '../../../assets/images/travel/example-plane.svg'
import Footnote from '../../../components/footnote/Footnote'
import Animations from '../../../utils/animations'
import useStyles from '../Travel.styles'

const images = [
  { src: image1, alt: 'Destination Image'},
  { src: image2, alt: 'Hotel Image'},
  { src: image3, alt: 'Food Image'},
  { src: image4, alt: 'Snorkeling Image'},
  { src: image5, alt: 'Relaxation Image'},
  { src: image6, alt: 'Sunset Image'}
]

export default function TravelExample() {
  const classes = useStyles()

  return (
    <Box p={3} className={classes.exampleContainer}>
      <Grid container>
        <Grid item xs={12} md={3} container className={classes.exampleImagesContainer}>
          {images.map(({src, alt}, index) => (
            <motion.img
              key={alt}
              src={src}
              alt={alt}
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.5 }}
              className={classes.exampleImage}
            />
          ))}
        </Grid>

        <Grid item xs={12} md={9} container direction='column' className={classes.exampleTextContainer}>
          <h1 className={classes.exampleTitle}>
            See it for yourself:
          </h1>
          <h2 className={classes.exampleSubtitle}>
            2 nights in Cancun, Mexico in September
          </h2>
          <div className={classes.exampleGridContainer}>
            <div className={classes.exampleOthersContainer}>
              <div>
                <p className={classes.exampleTextHeader} style={{ fontWeight: 'bold' }}></p>
                <p className={classes.exampleServiceText} style={{ fontWeight: 'bold' }}>2-night Hotel</p>
                <p className={classes.exampleServiceText} style={{ fontWeight: 'bold' }}>1-day Car Rental</p>
                <p className={classes.exampleServiceText} style={{ fontWeight: 'bold' }}>Snorkel + Speedboat Ride</p>
                <p className={classes.exampleServiceText} style={{ fontWeight: 'bold' }}>Cenote Tour</p>
              </div>
              <div>
                <p className={classes.exampleTextHeader}>Other Providers</p>
                <p className={classes.exampleText}>$816.00</p>
                <p className={classes.exampleText}>$32.00</p>
                <p className={classes.exampleText}>$60.00</p>
                <p className={classes.exampleText}>$161.00</p>
              </div>
            </div>
            <div className={classes.exampleMultikrdContainer}>
              <p className={classes.exampleMultikrdHeader}>Multikrd</p>
              <p className={classes.exampleMultikrdText}>$488.00</p>
              <p className={classes.exampleMultikrdText}>$27.00</p>
              <p className={classes.exampleMultikrdText}>$46.00</p>
              <p className={classes.exampleMultikrdText}>$120.00</p>
              <motion.p className={classes.exampleTotalAmount} {...Animations.SHAKE({ duration: 0.7, delay: 0.5 })}>$388.00</motion.p>
            </div>
            <div className={classes.exampleTotalContainer}>
              <h2 className={classes.exampleTotal}>Total Savings with Multikrd</h2>
            </div>
          </div>
          <div style={{ position: 'absolute', bottom: 0 }}>
            <Footnote notes={['Offers shown are displayed for advertising purposes only, real travel offers may vary.']} />
          </div>
        </Grid>
      </Grid>
      <img src={plane} alt='Plane' className={classes.examplePlane} />
    </Box>
  )
}
