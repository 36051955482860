import React from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import step1 from '../../../assets/images/how-it-works/steps-1.svg'
import step2 from '../../../assets/images/how-it-works/steps-2.svg'
import step3 from '../../../assets/images/how-it-works/steps-3.svg'
import Animations from '../../../utils/animations'
import Paths from '../../../utils/paths'
import useStyles from '../HowItWorks.styles'

export default function HowItWorksSteps () {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div style={{ paddingBottom: 40, paddingTop: 40 }}>
      <h1>Easy as 1-2-3</h1>
      <div className={classes.stepsSection}>
        <motion.img
          style={{cursor: 'pointer', zIndex: 2}}
          {...Animations.SLIDE_RIGHT({ delay: 0.3, duration: 0.3 })}
          src={step1}
          onClick={() => navigate(Paths.CONTACT)}
        />
        <motion.img
          style={{ zIndex: 2 }}
          {...Animations.SLIDE_RIGHT({ delay: 0.6, duration: 0.3 })}
          src={step2}
        />
        <motion.img
          style={{ zIndex: 2 }}
          {...Animations.SLIDE_RIGHT({ delay: 0.9, duration: 0.3 })}
          src={step3}
        />
      </div>
    </div>
  )
}