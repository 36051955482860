import React from 'react'
import { useNavigate } from 'react-router-dom'

import image from '../../../assets/images/travel/header.webp'
import Paths from '../../../utils/paths'
import useStyles from '../Travel.styles'

export default function TravelHeader () {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.headerContainer} onClick={() => navigate(Paths.CONTACT)}>
      <img alt='header-image' src={image} className={classes.headerImage} />
    </div>
  )
}