import { Button } from '@material-ui/core'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import PointBlack from '../../../assets/icons/point-black.png'
import PointWhite from '../../../assets/icons/point-white.png'
import { posts } from '../../../assets/content/Blog/Posts'
import Paths from '../../../utils/paths'
import useStyles from '../Home.styles'

const containerVariants = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3
    }
  }
}

const itemVariants = {
  hidden: { opacity: 0, y: 50 },
  show: { opacity: 1, y: 0 }
}

export default function HomeBlogPreview() {
  const classes = useStyles()
  const scrollRef = useRef(null)
  const navigate = useNavigate()

  const [selected, setSelected] = useState(1)

  const executeScroll = position => {
    setSelected(position)
    scrollRef.current.scrollTo(
      position === 1 ? 0 : position === 2 ? scrollRef.current.scrollHeight / 3 : 1000,
      0
    )
  }

  return (
    <div className='row py-4'>
      <div className='col-12 d-flex justify-content-center blog-title'>Blog</div>
      <div className='scroll-blog px-0' ref={scrollRef}>
        <motion.div
          className='col-12 row scroll-blog-div px-5 px-md-0'
          variants={containerVariants}
          initial='hidden'
          whileInView='show'
        >
          {posts.map((e, index) => {
            if (index < 3) {
              return (
                <motion.div key={e.title} className='col-4 p-4 p-lg-5' variants={itemVariants} transition={{duration: 0.2}}>
                  <Button
                    onClick={() => navigate(Paths.BLOG + '/' + index)}
                    className={classes.blogLink}
                  >
                    <div className='d-flex flex-column'>
                      <img src={e.img} alt='blog1' className='blog-img py-5 py-md-0 pe-0 pe-md-0' />
                      <div className='blog-sub-title py-4'>{e.title}</div>
                      <div className='blog-text pb-4'>{e.category[0]}</div>
                      <div className='blog-text'>{e.date}</div>
                    </div>
                  </Button>
                  <div />
                </motion.div>
              )
            } else return ''
          })}
        </motion.div>
      </div>
      <div className='col-12 d-flex d-md-none justify-content-center blog-title pt-5 '>
        <div>
          <Button className={classes.blogButton} onClick={() => executeScroll(1)}>
            <img src={selected === 1 ? PointBlack : PointWhite} alt='point' />
          </Button>
          <Button className={classes.blogButton}>
            <img
              src={selected === 2 ? PointBlack : PointWhite}
              alt='point'
              onClick={() => executeScroll(2)}
            />
          </Button>
          <Button className={classes.blogButton}>
            <img
              src={selected === 3 ? PointBlack : PointWhite}
              alt='point'
              onClick={() => executeScroll(3)}
            />
          </Button>
        </div>
      </div>
    </div>
  )
}
