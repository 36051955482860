import React from 'react'
import { useLocation } from 'react-router-dom'

import logo from '../../assets/images/logo.webp'
import { FooterAgreements, FooterApps, FooterDisclaimer, FooterNavigation, FooterSocial} from './components'
import useStyles from './Footer.styles'

export default function Footer() {
  const location = useLocation()
  const classes = useStyles()
  if (location.pathname.slice(1) === 'contact-us') return ''

  else {
    return (
      <div className={classes.container}>
        <div className='row pb-3 justify-content-between'>
          <div className='col-4 col-md-2 d-flex px-5'>
            <img alt='logo' src={logo} className={classes.logo} />
          </div>
          <div className='d-none d-md-flex col-md-7'>
            <FooterNavigation />
          </div>
          <div className='col-8 col-md-3 d-flex justify-content-end align-items-start px-5'>
            <FooterSocial />
          </div>
        </div>
        <div className='d-flex d-md-none px-5'>
          <FooterNavigation />
        </div>
        <div className='d-table d-md-inline-flex flex-row-reverse justify-content-between px-5 w-100'>
          <div className='col d-flex justify-content-center justify-content-md-end pb-4 pb-md-1'>
            <FooterApps />
          </div>
          <div
            className='regular-text d-flex  align-items-center justify-content-center'
          >
            <FooterAgreements />
          </div>
          <div className='col d-flex justify-content-start align-items-center footer-text-1' />
        </div>
        <FooterDisclaimer />
      </div>
    )
  }
}
