import React from 'react'

import SlideHeader from '../../components/slide-header/SlideHeader'
import SlideImage from '../../components/slide-image/SlideImage'
import Footnote from '../../components/footnote/Footnote'
import RedCardBlock from '../../components/red-card-block/RedCardBlock'
import headerImage from '../../assets/images/tips/header.webp'
import slide1 from '../../assets/images/tips/slide-1.webp'
import slide2 from '../../assets/images/tips/slide-2.svg'
import headerShape from '../../assets/images/shapes/blue-bottom-right.svg'
import Paths from '../../utils/paths'
import { TipsAccordion } from './components'
import useStyles from './Tips.styles'

export default function Tips () {
  const classes = useStyles()

  return (
    <div>
      <SlideHeader
        buttonText='Get in touch'
        buttonPath={Paths.SIGNUP}
        textPosition='left'
        image={headerImage}
        title='Convenient and On-Demand access to earned tips '
        text='At Multikrd, we believe you should have immediate access to your tips.'
        figure={headerShape}
        figureClass='figure-header-benefits'
      />
      <SlideImage
        title={
          <p>
            Efficient Tips Management with a <b>Disbursement System</b>
          </p>
        }
        text={
          <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
            <p>
              Multikrd knows the significance of gratuities in various industries as a crucial part of employee compensation. 
            </p>
            <p>Our early tip disbursement program stands as a seamless integration, offering unprecedented ease in management for employers and 24/7 access to earned tips for employees.</p>
            <Footnote notes={['Subject to Terms & Conditions.']} omitPadding/>
          </div>
        }
        image={slide1}
        button={false}
      />
      <TipsAccordion />
      <SlideImage
      textPosition='left'
        title='Empowering Employers and Employees'
        text={
          <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
            <p>
              Employers can efficiently manage, administer, and disburse tips on a daily basis as per their schedule. Our integration with diverse Point-of-sale (POS) systems ensure convenience for employers, allowing easy management of employees and balances through file uploads or manual entries.
            </p>
            <p>Our platform ensures that daily tip payments are a hassle-free process. With user-friendly interfaces and simplified management screens, employers remain informed and in control, while employees may enjoy 24/7 access to their earned tips.</p>
            <Footnote notes={['Subject to Terms & Conditions.']} omitPadding />
          </div>
        }
        image={slide2}
        button={false}
      />
      <div>
        <h1 className={classes.blueTitle}>Earn, Request, Receive – Instantly</h1>
      </div>
      <RedCardBlock title='Ready to get started?' buttonText='Contact us' />
    </div>
  )
}
