import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  animationContainer: {
    position: 'absolute', 
    right: 80, 
    top: -24, 
    width: '30%',
    [theme.breakpoints.down('md')]: {
      // top: 40
      top: '20%'
    },
    [theme.breakpoints.down('xs')]: {
      top: -40
    }
  },
  button: {
    backgroundColor: 'white',
    borderRadius: 24,
    color: 'black',
    padding: 8,
    paddingInline: 32,
    textTransform: 'none'
  },
  image: {
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '80%'
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%', 
    justifyContent: 'space-evenly', 
    paddingTop: 40, 
    paddingBottom: 40, 
    paddingRight: 40,
    [theme.breakpoints.down('md')]: {
      paddingRight: 0
    }
  }
}), { classNamePrefix: 'SlideImage' })