import React from 'react'
import { IconButton } from '@material-ui/core'

import { appStore, googlePlay } from '../../../assets/images'
import { openWindow } from '../../../utils/helpers'
import useStyles from '../Footer.styles'

const APP_STORE = 'https://apps.apple.com/us/app/multikrd/id1546988080'
const GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=com.multikrd.android'

export default function FooterSocial() {
  const classes = useStyles()

  return (
    <div className={classes.appsContainer}>
      <p className={classes.appsTitle}>Download the mobile app</p>
      <div>
        <IconButton
          onClick={() => openWindow(APP_STORE)}
          aria-label='App Store'
          className='ps-0'
        >
          <img className={classes.appsImage} src={appStore} alt='App Store' />
        </IconButton>
        <IconButton
          onClick={() => openWindow(GOOGLE_PLAY)}
          aria-label='Google Play'
        >
          <img className={classes.appsImage} src={googlePlay} alt='Google Play' />
        </IconButton>
      </div>
    </div>
  )
}