import React from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import image from '../../../assets/images/wage-access/header.webp'
import notification from '../../../assets/images/wage-access/header-notification.svg'
import figure from '../../../assets/images/shapes/red-bottom-left.svg'
import Animations from '../../../utils/animations'
import Paths from '../../../utils/paths'
import useStyles from '../WageAccess.styles'

export default function WageAccessHeader () {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.headerContainer} onClick={() => navigate(Paths.CONTACT)}>
      <motion.img src={notification} className={classes.headerNotification} {...Animations.SLIDE_DOWN()} />
      <img src={figure} alt='header-shape' className={classes.headerFigure} />
      <img src={image} alt='header-image' className={classes.headerImage} />
    </div>
  )
}