import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  buttonBlack: {
    textTransform: 'none',
    backgroundColor: 'black !important',
    color: 'white !important',
    borderRadius: 30,
    paddingInline: 25,
    paddingTop: 10,
    paddingBottom: 10,
    minWidth: '10rem'
  }
}), { classNamePrefix: 'SlideHeader' })