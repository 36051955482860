import React from 'react'
import { motion } from 'framer-motion'

import cardImage from '../../../assets/images/how-it-works/integration-card.svg'
import cashbackImage from '../../../assets/images/how-it-works/integration-cashback.svg'
import logoImage from '../../../assets/images/how-it-works/integration-logo.svg'
import rewardsImage from '../../../assets/images/how-it-works/integration-rewards.svg'
import tipsImage from '../../../assets/images/how-it-works/integration-tips.svg'
import wageAccessImage from '../../../assets/images/how-it-works/integration-wage-access.svg'
import placeholderImage from '../../../assets/images/how-it-works/integration-placeholder.svg'
import circleShape from '../../../assets/images/shapes/green-circle.svg'
import Animations from '../../../utils/animations'
import useStyles from '../HowItWorks.styles'

export default function HowItWorksIntegration () {
  const classes = useStyles()

  return (
    <div className='d-flex pb-5 header-container row px-1 px-sm-5 px-md-1' style={{ backgroundColor: 'rgba(193, 216, 47, 0.5)' }}>
      <div className='col-12 col-md-6 d-flex align-items-center p-1 pt-5 p-md-5 justify-content-center' style={{position: 'relative'}}>
        <img src={circleShape} alt='circle-shape' className={classes.integrationImage} />
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', position: 'absolute'}}>
          <motion.img src={logoImage} alt='logo' {...Animations.POP({ delay: 0.3 })}  className={classes.integrationImage} />
          <motion.img src={cardImage} alt='card' {...Animations.POP({ delay: 0.1 })}  className={classes.integrationImage} />
          <img src={placeholderImage} alt='placeholder'  className={classes.integrationImage} />

          <motion.img src={wageAccessImage} alt='wage-access' {...Animations.POP({ delay: 0.2 })}  className={classes.integrationImage} />
          <img src={placeholderImage} alt='placeholder'  className={classes.integrationImage} />
          <motion.img src={rewardsImage} alt='rewards' {...Animations.POP({ delay: 0.4 })}  className={classes.integrationImage} />

          <motion.img src={cashbackImage} alt='cashback' {...Animations.POP({ delay: 0.5 })}  className={classes.integrationImage} />
          <motion.img src={tipsImage} alt='tips' {...Animations.POP({ delay: 0.3 })}  className={classes.integrationImage} />
          <img src={placeholderImage} alt='placeholder'  className={classes.integrationImage} />
        </div>
      </div>
      <div className='col-12 col-md-6 d-flex align-items-center p-1 pt-5 p-md-5'>
        <div className='d-flex flex-column p-1 p-lg-5 header-benefits-text-container'>
          <h1 className='web-header pb-4'>
            Fully customizable with
            seamless payroll integration
          </h1>
          <h2 className='large-text pb-3'>
            Our suite of tools allows you to connect with your hardworking teams to reward performance.
            Using our modular design, employers can fully customize the platform’s look, feel and functionality. 
          </h2>
          <h2 className='large-text pb-3'>
            Multikrd integrates seamlessly with most existing payroll systems, removing administrative burdens from PEO and HR teams.
          </h2>
        </div>
      </div>
    </div>
  )
}