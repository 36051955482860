import React from 'react'
import { motion } from 'framer-motion'

import cash from '../../assets/images/employees/cash.svg'
import Animations from '../../utils/animations'

const dollars = [
  { direction: 'left', delay: 0, endingX: '-50%', endingY: '60%', scale: 1 },
  { direction: 'right', delay: 0.8, endingX: '-80%', endingY: '20%', scale: 0.9 },
  { direction: 'left', delay: 1.5, endingX: '-10%', endingY: '30%', scale: 1.1 },
  { direction: 'right', delay: 2, endingX: '-40%', endingY: '0%', scale: 0.9 }
]

export default function AnimatedDollars() {

  return (
    <>
      {dollars.map((props, index) => (
        <motion.img key={index} src={cash} alt='cash' style={{ position: 'absolute', width: 160 }}
          {...Animations.FALLING_LEAF({ ...props })}
        />
      ))}
    </>
  )
}
