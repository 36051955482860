import React from 'react'
import { motion } from 'framer-motion'

import image from '../../../assets/images/tips/accordion.webp'
import Accordion from '../../../components/accordion/Accordion'
import Footnote from '../../../components/footnote/Footnote'
import Animations from '../../../utils/animations'
import useStyles from '../Tips.styles'

export default function TipsAccordion () {
  const classes = useStyles()

  return (
    <>
      <div className={classes.accordionContainer}>
          <div className={classes.accordionCaptionContainer}>
          <h1 className={classes.accordionTitle}>Get your tips when you earn them</h1>
            <motion.img className={classes.accordionCaptionImage} src={image} alt='Employers' 
                {...Animations.POP()}
              />
          </div>
        <div className={classes.accordionEmployersContainer}>
          <div className='web-subhead pb-5'>Benefits of Tips On-Demand:</div>
          <div className='pb-5 mb-5'>
            <Accordion content={accordion} />
          </div>
          <Footnote notes={['Subject to Terms & Conditions.']} omitPadding />
        </div>
      </div>
    </>
  )
}

const accordion = [
  {
    title: 'Instant Access to Tips',
    textLine1: 'Receive your tips immediately after each shift, no waiting required.'
  },
  {
    title: 'Financial Flexibility',
    textLine1: 'Cover unexpected expenses or manage your daily finances with ease.'
  },
  {
    title: 'Increased Convenience',
    textLine1: 'Enjoy the convenience of having your tips available anytime you need them.'
  },
  {
    title: 'Reduced Financial Stress',
    textLine1: 'Avoid the stress of waiting for payday and take control of your finances.'
  }
]