import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

import SlideImg from '../components/SlideImg'
import SlideHeader from '../components/SlideHeader'
import SlideCarousel from '../components/SlideCarousel'
import ImgHeader from '../assets/images/employees-header.png'
import ImgHeaderSm from '../assets/images/employees-header-sm.png'
import CurstomerImg1 from '../assets/images/customers/img-1.png'
import CurstomerImg2 from '../assets/images/customers/img-2.png'
import CarouselImg1 from '../assets/images/carrousel/employees-1.png'
import CarouselImg2 from '../assets/images/carrousel/employees-2.png'
import CarouselImg3 from '../assets/images/carrousel/employees-3.png'
import Img2 from '../assets/images/employees/savings.svg'
import Img3 from '../assets/images/employees/cashback.svg'
import CardImg from '../assets/images/card.svg'
import Icon1 from '../assets/images/employees/card-wage-access.svg'
import Icon2 from '../assets/images/employees/card-cashback.svg'
import Icon3 from '../assets/images/employees/card-wallet.svg'
import FigureHeader from '../assets/images/shapes/employees-header.png'
import Paths from '../utils/paths'

const useStyles = makeStyles(theme => ({
  buttonSecondaryBlack: {
    textTransform: 'none',
    backgroundColor: 'black !important',
    color: 'white !important',
    borderRadius: 30,
    paddingInline: 25,
    paddingTop: 10,
    paddingBottom: 10,
    minWidth: '10rem'
  },
  buttonSecondaryWhite: {
    textTransform: 'none',
    backgroundColor: 'white !important',
    border: 0,
    color: 'black !important',
    borderRadius: 30,
    paddingInline: 25,
    paddingTop: 10,
    paddingBottom: 10,
    minWidth: '10rem'
  }
}))

export default function Curstomers () {
  const navigate = useNavigate()
  const classes = useStyles()

  return (
    <div>
      <SlideHeader
        buttonText='Signup now'
        buttonPath={Paths.SIGNUP}
        textPosition='right'
        img={ImgHeader}
        imgSm={ImgHeaderSm}
        title='Reap rewards, gain financial control, and save every day'
        text='Multikrd is a set of financial tools aimed at helping you to improve your personal budget management, and gain financial flexibility.'
        figure={FigureHeader}
        figureClass='figure-header-employees'
      />
      <div className='slide-employees-blue pb-5'>
        <div className='row'>
          <img className='col-12 col-sm-6 p-5' src={CardImg} alt='cardMk' />
          <div className='col-12 col-sm-6 d-flex align-items-center'>
            <div
              className='d-flex flex-column p-4 slide-employees-blue-header-container'
              style={{ color: 'white' }}
            >
              <div className='web-header text-start'>Introducing the MK Card</div>
              <div className='largue-text text-start pt-4'>
                It’s never been easier to improve your financial well-being.
              </div>
              <div className='d-flex justify-content-center pt-5'>
                <Button
                  variant='outlined'
                  className={classes.buttonSecondaryWhite}
                  onClick={() => navigate(Paths.SIGNUP)}
                >
                  I want my MK card!
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className='row pb-5 pb-lg-0'>
          {cardsBluePanel.map(e => {
            return (
              <div
                key={e.title}
                className='col-12 col-sm-6 col-md-4 p-2 p-lg-5'
                style={{ color: 'white' }}
              >
                <img
                  className='py-0 mb-3 mb-lg-0 mt-5 mt-sm-3 py-lg-5 slide-employees-blue-icon'
                  src={e.icon}
                  alt='icon'
                />
                <div className='web-subhead text-start px-5 pt-1 pb-4'>{e.title}</div>
                <div className='text-regular text-start px-5'>{e.text}</div>
              </div>
            )
          })}
        </div>
      </div>
      <SlideImg
        button={false}
        title={
          <div className='web-header d-flex flex-column '>
            Personalized savings— anytime anywhere
          </div>
        }
        text='We use smart recommendations and location-based alerts to send you relevant and timely promotions—both online and in-store. Save money every time you shop, whether you’re eating out, buying basics, or planning a trip.'
        img={Img2}
        textPosition='left'
      />
      <SlideImg
        button={false}
        title='Get real cashback'
        text='Earn up to 20% cashback on daily essentials—at stores where you already shop. National brands are part of our program. You can receive your cashback to your MK card or through a no-fee transfer to another account of your choice.'
        img={Img3}
      />
      <div className='py-5' />

      <div className='slide-orange p-5'>
        <div className='row'>
          {/* <div className='col-12 col-sm-2 p-5' /> */}
          <img
            className='col-12 col-sm-6 p-5 side-image'
            src={CurstomerImg1}
            alt='International Money'
          />
          <div className='col-12 col-sm-6 d-flex align-items-center'>
            <div className='d-flex flex-column p-4 slide-customers-header-container'>
              <div className='web-subhead text-start'>International Money</div>
              <div className='web-subhead text-start'>Transfers Made Easy</div>
              <div className='largue-text text-start pt-4'>
                Send money to over 165 countries, benefiting from the best foreign exchange rates,
                ensuring that your recipient always receives more money with each transaction
                compared to other providers. Plus, with over 500,000 pickup points and multiple
                delivery methods available, your recipient will never face challenges when it comes
                to accessing their funds.
              </div>
              <div className='d-flex justify-content-center pt-5'>
                <Button
                  variant='outlined'
                  className={classes.buttonSecondaryWhite}
                  onClick={() => navigate(Paths.SIGNUP)}
                >
                  Start sending money
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='slide-cyan p-5'>
        <div className='row'>
          {/* <div className='col-12 col-sm-2 p-5' /> */}
          <div className='col-12 col-sm-6 d-flex align-items-center'>
            <div className='d-flex flex-column p-4 slide-customers-header-container'>
              <div className='web-subhead text-start'>One-stop-shop for all your bill payments</div>
              <div className='largue-text text-start pt-4'>
                Gain access to a catalog of +23k billers spanning every category you can imagine:
                from streaming services to phone bills, mortgage payments to college savings plans,
                insurance premiums, electricity bills, credit card payments, rent, tuition fees, and
                even taxes.
              </div>
              <div className='d-flex justify-content-center pt-5'>
                <Button
                  variant='outlined'
                  className={classes.buttonSecondaryBlack}
                  onClick={() => navigate(Paths.SIGNUP)}
                >
                  Check billers catalog
                </Button>
              </div>
            </div>
          </div>
          <img
            className='col-12 col-sm-6 p-5 side-image'
            src={CurstomerImg2}
            alt='International Money'
          />
        </div>
      </div>

      <SlideCarousel items={carouselItems} />
    </div>
  )
}

const cardsBluePanel = [
  {
    title: 'Your Key to banking freedom',
    text: 'Enjoy seamless banking, including deposits, withdrawals, and transfers. Access online and in-person payments with our unique Secured Credit Card.',
    icon: Icon1
  },
  {
    title: 'No more back and forth with cash⁽¹⁾',
    text: 'You can set up your payroll direct deposit and manage your finances from the app. Make online and in-person payments and all kind of transfers using your MK Card and Account, and unlock additional benefits such as free wage access.',
    icon: Icon2
  },
  {
    title: 'We travel with you – always at hand',
    text: 'Access your MK card through Apple Pay or Google Pay, or simply keep a card in your wallet. Easy peasy.',
    icon: Icon3
  }
]

const carouselItems = [
  {
    title: '“Now I can make online payments and get cash at any ATM”',
    text: ' I never had a bank account until I started this job. My employer offered this solution and I immediately requested a card. Now I can make online payments and get cash at any ATM. ',
    autor: '- Jonathan T., Electrician',
    img: CarouselImg1,
    design: 3
  },
  {
    title: 'How I saved over $500',
    text: 'Last time I traveled with my husband and two kids, we booked through my app and we not only saved on hotels, but also purchased discounted theme park tickets, AND got cashback! ',
    autor: '- Jessica S., Speech Pathologist',
    header: 'USER STORY:',
    img: CarouselImg2,
    design: 2
  },
  {
    title: '“I love the Auto Wage Access feature”',
    text: 'I use my employee portal to look at my account balance, transfer money, make purchases, and request my earned wages. I love the Auto Wage Access feature. I know that every Monday I will be receiving what I want to spend that week.',
    autor: '- Caroline M., Receptionist',
    img: CarouselImg3,
    design: 1
  }
]
