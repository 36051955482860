import React from 'react'
import { Button } from '@material-ui/core'

import { openWindow } from '../../../utils/helpers'
import useStyles from '../Footer.styles'

const LEGAL_AGREEMENTS = 'https://platform.accesswages.com/legal-agreements'
const PRIVACY_POLICY = 'https://platform.accesswages.com/legal-agreements?section=Privacy%20Policy'

export default function FooterSocial() {
  const classes = useStyles()
  return (
    <>
      <div className='pe-3'>
        <Button
          className={classes.agreementsButton}
          onClick={() => openWindow(LEGAL_AGREEMENTS)}
          >
          Legal Agreements
        </Button>
      </div>
      |
      <div className='ps-3'>

        <Button
          className={classes.agreementsButton}
          onClick={() => openWindow(PRIVACY_POLICY)}
        >
          Privacy policy
        </Button>
      </div>
    </>
  )
}