import React from 'react'

import SlideImage from '../../components/slide-image/SlideImage'
import RedCardBlock from '../../components/red-card-block/RedCardBlock'
import Footnote from '../../components/footnote/Footnote'
import AnimatedDollars from '../../components/animated-dollars/AnimatedDollars'
import slide1 from '../../assets/images/wage-access/slide-1.svg'
import { WageAccessAccordion, WageAccessHeader, WageAccessSteps } from './components'
import useStyles from './WageAccess.styles'

export default function WageAccess () {
  const classes = useStyles()

  return (
    <div>
      <WageAccessHeader />
      <SlideImage
        animation={<AnimatedDollars />}
        title={
          <p>
            Access Your Earned Wages <b>Anytime</b>⁽¹⁾
          </p>
        }
        text={
          <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
            <p>
              At Multikrd, we understand the importance of financial stability. Our Earned Wage Access (EWA) program lets you access your earned wages before payday⁽¹⁾, helping you manage your finances more effectively and reduce financial stress.
            </p>
            <Footnote notes={['Subject to Terms & Conditions.']} omitPadding />
          </div>
        }
        image={slide1}
        button={false}
      />
      <WageAccessAccordion />
      <WageAccessSteps />
      <div>
        <h1 className={classes.redTitle}>Easy, Fast, Reliable</h1>
      </div>
      <RedCardBlock title='Ready to sign up?' buttonText='Create an Account' goToPlatform />
    </div>
  )
}
