import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  accordionCaption: {
    fontSize: 22
  },
  accordionCaptionAccent: {
    color: '#0097EC',
    fontSize: 22,
    fontWeight: 'bold'
  },
  accordionCaptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    textAlign: 'left',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '80%'
    }
  },
  accordionCaptionImage: {
    alignSelf: 'center',
    marginBottom: 8,
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '80%'
    }
  },
  accordionColumn: {
    flex: 1
  },
  accordionContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 56,
    marginBottom: 40,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: 32,
      marginBottom: 16
    }
  },
  accordionEmployersContainer: {
    flex: 1.2,
    paddingLeft: 56,
    paddingRight: 0,
    paddingTop: 40,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 16
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  accordionEmployeesContainer: {
    flex: 1.2,
    paddingLeft: 0,
    paddingRight: 56,
    paddingTop: 40,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 16
    }
  },
  accordionTitle: {
    marginBottom: 32,
    textAlign: 'left' 
  },
  headerContainer: {
    marginBottom: 32,
    position: 'relative'
  },
  headerFigure: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    }
  },
  headerImage: {
    width: '90%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  headerNotification: {
    left: 0,
    margin: '0 auto',
    position: 'absolute',
    right: 0,
    top: 56,
    [theme.breakpoints.down('md')]: {
      top: 16,
      width: '40%'
    }
  },
  blueTitle: { 
    color: '#00A4E4',
    fontSize: 64,
    fontWeight: 'bold',
    paddingBottom: 56,
    paddingTop: 56,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      paddingBottom: 32,
      paddingTop: 32,
    }
  }
}), { classNamePrefix: 'Tips' })