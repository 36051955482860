import React, { useState } from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { ArrowForwardIosOutlined } from '@material-ui/icons'

import Paths from '../../utils/paths'

export default function FAQs () {
  const [expanded1, setExpanded1] = useState(false)
  const [expanded2, setExpanded2] = useState(false)
  const [expanded3, setExpanded3] = useState(false)
  const [expanded4, setExpanded4] = useState(false)

  const handleChange1 = panel => (event, isExpanded) => {
    setExpanded1(isExpanded ? panel : false)
  }
  const handleChange2 = panel => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false)
  }
  const handleChange3 = panel => (event, isExpanded) => {
    setExpanded3(isExpanded ? panel : false)
  }
  const handleChange4 = panel => (event, isExpanded) => {
    setExpanded4(isExpanded ? panel : false)
  }

  return (
    <div className='px-1 px-md-5 pb-5'>
      <div className='row p-5 px-3 px-sm-5'>
        <div className='web-header text-center pb-5'>Frequently Asked Questions</div>
        <div className='web-subhead pt-5 pb-3'>Wage Access</div>
        {wageAccessAcordionContent.map((e, index) => {
          return (
            <Accordion
              key={index}
              className='accordion-item-faqs'
              expanded={expanded1 === 'panel' + index}
              onChange={handleChange1('panel' + index)}
            >
              <AccordionSummary
                // expandIcon={<img src={Arrow} alt='more' />}
                expandIcon={<ArrowForwardIosOutlined />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <div className='accordion-title'>{e.question}</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className='accordion-text'>{e.answer}</div>
              </AccordionDetails>
            </Accordion>
          )
        })}
        <div className='web-subhead pt-5 pb-3 mt-5'>Card</div>
        {cardAcordionContent.map((e, index) => {
          return (
            <Accordion
              key={index}
              className='accordion-item-faqs'
              expanded={expanded2 === 'panel' + index}
              onChange={handleChange2('panel' + index)}
            >
              <AccordionSummary
                // expandIcon={<img src={Arrow} alt='more' />}
                expandIcon={<ArrowForwardIosOutlined />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <div className='accordion-title'>{e.question}</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className='accordion-text'>{e.answer}</div>
              </AccordionDetails>
            </Accordion>
          )
        })}
        <div className='web-subhead pt-5 pb-3 mt-5'>Discounts</div>
        {discountsAcordionContent.map((e, index) => {
          return (
            <Accordion
              key={index}
              className='accordion-item-faqs'
              expanded={expanded3 === 'panel' + index}
              onChange={handleChange3('panel' + index)}
            >
              <AccordionSummary
                // expandIcon={<img src={Arrow} alt='more' />}
                expandIcon={<ArrowForwardIosOutlined />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <div className='accordion-title'>{e.question}</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className='accordion-text'>{e.answer}</div>
              </AccordionDetails>
            </Accordion>
          )
        })}
        <div className='web-subhead pt-5 pb-3 mt-5'>Cashback</div>
        {cashbackAcordionContent.map((e, index) => {
          return (
            <Accordion
              key={index}
              className='accordion-item-faqs'
              expanded={expanded4 === 'panel' + index}
              onChange={handleChange4('panel' + index)}
            >
              <AccordionSummary
                // expandIcon={<img src={Arrow} alt='more' />}
                expandIcon={<ArrowForwardIosOutlined />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <div className='accordion-title'>{e.question}</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className='accordion-text'>{e.answer}</div>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

const discountsAcordionContent = [
  {
    question: 'How do I search for merchants and offers?',
    answer: (
      <>
        Offers are categorized by location so that search results depend on where the user is
        located.
        <br />
        <br />
        By default, smartphones and browsers obtain the geo-data required to run queries on mobile
        and desktop devices. In addition, member-supplied data such as zip code and address are used
        to customize offers.{' '}
      </>
    )
  },
  {
    question: 'How do I search by category?',
    answer: (
      <>
        Each user can make a better search by selecting from the menu bar options such as “Dining”
        or “Shopping”, and then selecting the subcategory or area of interest.
      </>
    )
  },
  {
    question: 'How do I redeem an offer or coupon?',
    answer: (
      <>
        Once you find a coupon that you’d like to use, there are three ways in which you can redeem
        it, and each offer may have one or more options.
        <br />
        <br />
        Mobile - Click on “Use Coupon Now”. You will be asked if you are ready to redeem the mobile
        coupon. Click “Yes” and the coupon will appear on your screen. In some cases, a bar code
        will be displayed that can be scanned by the merchant. In other cases, a code will appear
        that you need to give to the merchant so that they apply the offer to your purchase.
        <br />
        <br />
        Print - Click on “Print for later”. This will take you to a page where you can print your
        coupon and redeem it by bringing it with you when you visit the merchant and make a
        purchase.
        <br />
        <br />
        Click URL - Click on “Website Coupon”. You will be shown a code that must be entered when
        asked while setting up your payment information on the merchant website.
        <br />
        <br />
        Make sure you have pop-ups and redirects enabled!
      </>
    )
  },
  {
    question: 'How do I report an issue with a coupon or merchant?',
    answer: (
      <>
        Please{' '}
        <a href={Paths.CONTACT_US} target='_blank' rel='noreferrer'>
          contact us{' '}
        </a>{' '}
        and provide your name, coupon or offer, and merchant’s location.
      </>
    )
  }
]

const wageAccessAcordionContent = [
  {
    question: 'How do I protect my personal information?',
    answer: (
      <>
        We take appropriate security measures including physical, electronic and procedural measures
        to help safeguard your personal information from unauthorized access and disclosure. In
        addition, we use encryption in the transmission of your sensitive Personal Information
        between your system and ours, and we use firewalls to help prevent unauthorized persons from
        gaining access to your Personal Information.
        <br />
        <br /> Users should avoid sending Personal Information through insecure email. <br />
        <br />
        To protect your privacy and security, never share your username or password and always log
        out of the platform as soon as you are finished using the service. <br />
        <br />
        The Payment Card Industry Data Security Standard (PCI-DSS) aims to enhance security for
        consumers by setting guidelines for any company that accepts, stores, processes, or
        transmits credit card information — regardless of the number of transactions or the size of
        those transactions. This is a requirement industry-wide to ensure every business is doing
        their part to protect their consumers.
      </>
    )
  },
  {
    question: 'Is my wage advance a loan?',
    answer: (
      <>
        No, your Wage Advance is not a loan. It is an advance on your earned wages. This service
        allows you to easily cover your expenses without taking out a loan or incurring high
        interest debt.
        <br />
        <br />
        The Consumer Financial Protection Bureau, or CFPB, is the entity that unofficially regulates
        earned wage access products. It has not released any official regulations towards EWA, but
        in their latest advisory opinion, effective December 10, 2020, it released criteria and
        guidelines for a wage access product to not involve a credit, thus being exempt from the
        federal Truth in Lending Act (TILA) and Regulation Z lending requirements.
        <br />
        <br />
        According to the CFPB, in its frequently asked questions and under 12 CFR §1041.3(d)(7),
        advances of wages are not credit and are thus excluded from the Payday Lending Rule provided
        that (1) the advance is made by a company that provides consumer financial products as part
        of the employers benefits program; (2) the advance is extended to the employer’s employee;
        and (3) the advance is made only against the accrued cash value of any wages the employee
        has earned up to the date of the advance.{' '}
      </>
    )
  },
  {
    question: 'Do you report my Early or Auto Wage Access requests to any credit bureaus? ',
    answer: (
      <>
        No. Any wage advance you request is not reported to any of the credit bureaus as these are
        not considered loans as defined by the Consumer Financial Protection Bureau (“CFPB”).{' '}
      </>
    )
  },
  {
    question: 'Does my employer know about my Early and Auto Wage Access requests?',
    answer: (
      <>
        We work directly with your HR and Payroll Departments to communicate the wage advance
        request. These requests are then deducted from the paycheck issued by your company on your
        next payroll. We encourage you to reach out to your HR representative and inquire who will
        know within your company.
      </>
    )
  },
  {
    question: 'How much money can I request in a single transaction?',
    answer: (
      <>
        Generally, you can request up to 100% of your net earned wages since your last pay date.
        Within a single pay period, there is not a maximum number of requests you can make; however,
        your available amount for request will be capped at 100% of your currently net earned wages
        since your last pay date, or $500 per transaction. Please note that every employer can set
        limits to the percentage of earned wages that a user can request per transaction and to the
        number of requests per pay period.
      </>
    )
  },
  {
    question:
      'Is there a limit on the number of wage advance requests that I make in a single pay period?',
    answer: (
      <>
        There is not a maximum number of requests you can make during the same pay period. You can
        make as many requests as you would like as long as there is balance available on your
        account. Please note that an Auto Wage Access requires a minimum balance of $10 USD per
        transaction.
      </>
    )
  },
  {
    question: 'When will the funds be available in my account?',
    answer: (
      <>
        When you make an Earned Wage Access request, you will be required to select when you want to
        receive the funds. You can choose to receive the funds in 1-3 business days, or an instant
        option where you will see the funds within one hour of your request.
        <br />
        <br />
        Auto Wage Access allows you to decide when you want to receive your wage advance. You can
        set up or modify the frequency and amount that you want to be receiving during a pay period.{' '}
      </>
    )
  },
  {
    question: 'How and when is my Wage Access Repaid?',
    answer: (
      <>
        There is no action you need to take or do.
        <br />
        <br />
        We are in direct communication with your Payroll Department. This allows us to stay
        up-to-date with requests made during each pay period. Your employer will process a deduction
        from your paycheck for the total amount that was advanced including transaction fees. Your
        employer will then send us the funds to repay those wage advances we made available to you.{' '}
      </>
    )
  },
  {
    question: 'Do I need a bank account or card to request an Earned Wage Access? ',
    answer: (
      <>
        You can select to have the funds transferred onto your personal debit card or bank
        information, but if you do not have one, you can always request a card from us by simply
        following the instructions on your portal. You can choose to have a virtual, physical card,
        or both and this will allow you to receive the funds to that account.{' '}
      </>
    )
  }
]

const cashbackAcordionContent = [
  {
    question: 'How does the cashback service work?',
    answer: (
      <>
        Our platform allows you to earn cash rewards while shopping online. Through a network of
        affiliate businesses, we offer customers the possibility to shop with nearly 8,000 U.S.
        online retailers.
        <br />
        <br />
        Browse through the “Cashback” section under rewards and explore all the saving opportunities
        available to you. Once you find an attractive offer, click on “Shop Now” and you will be
        redirected to the merchant’s website. Our technology will track your purchase and we will be
        able to reward you the amount of cashback that the merchant offers you. Please note that if
        you open the merchant’s website separately, you will not be awarded cashback. Cashback is
        earned only on eligible purchases initiated using this platform.
      </>
    )
  },
  {
    question: 'Will I be charged for this service?',
    answer: <>No. Cashback opportunities are part of your membership and can be enjoyed for free.</>
  },
  {
    question: 'How much cashback will I receive?',
    answer: (
      <>
        You can receive up to 30% cashback on participating stores. Each store has unique offers and
        not all purchases are eligible for cashback. Each store reserves the right to exclude
        certain products or services from eligibility.
      </>
    )
  },
  {
    question: 'What is the difference between “Available” “Pending” and “Historic” cashback?',
    answer: (
      <>
        “Pending” status indicates that a transaction has been tracked, and it is in the process of
        being verified (and approved) by the brand.
        <br />
        <br />
        “Available” means that we have received the cashback rewards from the retailer, and you may
        now withdraw, use it or donate it.
        <br />
        <br />
        “Historic” reflects the total cashback rewards that you’ve earned in the course of using our
        platform.
      </>
    )
  },
  {
    question: 'How may I withdraw money?',
    answer: (
      <>
        You can transfer your cashback funds to any card by simply going to “Redeem Cashback”,
        selecting the amount you want to transfer, and following the steps on the screen.
      </>
    )
  },
  {
    question: 'What purchases are not eligible for cashback?',
    answer: (
      <>
        Not all purchases are eligible for cashback. Each store reserves the right to exclude
        certain products or services from eligibility and users are subject to such exclusions. Make
        sure to read the offer’s description for more details before making a purchase.
      </>
    )
  }
]

const cardAcordionContent = [
  {
    question: 'Does getting a card negatively affect my credit score?',
    answer: (
      <>
        No. Multikrd is a Financial Technology Company (Fintech) and not a FDIC-insured bank.
        Checking accounts and the Multikrd Visa® Debit Card are provided by Lewis & Clark Bank,
        Member FDIC. Because this is a checking account and a debit card, it does not affect in any
        manner your credit score, or is reported to any boureaus.
      </>
    )
  },
  {
    question: 'What is the cost for getting a card?',
    answer: <>There is no cost for obtaining this card. Get yours today!</>
  },
  {
    question: 'How can I apply to get a card?',
    answer: (
      <>
        It is very easy to apply for a card! You will need to log in into your account and click on
        the “Get the card” button. You will need to fill out and submit the application for review.
        Make sure all of your information is correct and you agree to the terms and conditions. We
        will email you with updates on your application.{' '}
      </>
    )
  },
  {
    question: 'How long does it take to receive my card?',
    answer: (
      <>
        Once you submit the application to request a card, most of the time you will be approved
        right away. Sometimes it takes us one to two business days to process your application. Once
        approved, your virtual card will be available in seconds. Physical cards can take up to 14
        days to arrive at the address you provide.
      </>
    )
  },
  {
    question: 'How can I activate my card?',
    answer: (
      <>
        Simply follow the steps on your portal, set up your pin, and don’t forget to add your
        physical card to your mobile device’s wallet for easy access.
      </>
    )
  },
  {
    question: 'Where do I check my balance?',
    answer: (
      <>
        You can check your account’s balance by logging in into your portal and looking at your Home
        or Card tabs. You can see all your past transactions and pay advances there as well.{' '}
      </>
    )
  },
  {
    question: 'Where can I use my card?',
    answer: (
      <>
        You can use your card to make online and in-person payments at any store or merchant.
        Additionally, we have partnered with Moneypass to provide surcharge-free ATM access at over
        37,000 ATMs nationwide. Lastly, you can transfer money from your account to a friend or
        family member using the app or web platform.
      </>
    )
  },
  {
    question: 'What do I do if I lost my card, or it got stolen?',
    answer: (
      <>
        You can easily lock and unlock your card under Card Details inside your portal. There are
        also options to replace a damaged card, report it as lost or stolen to cancel it and get a
        new one, or cancel it.
      </>
    )
  }
]
