import React from 'react'
import { motion } from 'framer-motion'

import circles from '../../../assets/images/wage-access/accordion.svg'
import Accordion from '../../../components/accordion/Accordion'
import Footnote from '../../../components/footnote/Footnote'
import Animations from '../../../utils/animations'
import useStyles from '../WageAccess.styles'

export default function WageAccessAccordion () {
  const classes = useStyles()

  return (
    <>
      <div className={classes.accordionContainer}>
          <div className={classes.accordionCaptionContainer}>
            <h1 className={classes.accordionTitle}>Be in control of payday!</h1>
            <motion.img className={classes.accordionCaptionImage} src={circles} alt='Employers' 
                {...Animations.POP()}
              />
          </div>
        <div className={classes.accordionEmployersContainer}>
          <div className='web-subhead pb-5'>Benefits of EWA:</div>
          <div className='pb-5 mb-5'>
            <Accordion content={accordion} />
          </div>
        </div>
      </div>
      <Footnote notes={[
        '(1) Source: Bankrate Survey, April 2023',
        '(2) Subject to Terms & Conditions.'
      ]} />
    </>
  )
}

const accordion = [
  {
    title: 'Immediate Access to Your Wages',
    textLine1: 'Get your earned wages whenever you need them, without waiting for payday.⁽²⁾'
  },
  {
    title: 'Financial Flexibility',
    textLine1: 'Cover unexpected expenses or manage your cash flow with ease.'
  },
  {
    title: 'Reduced Financial Stress',
    textLine1: 'Take control of your finances and reduce the stress of waiting for your paycheck.'
  },
  {
    title: 'Increased Satisfaction',
    textLine1: 'Feel supported and valued by your employer with the financial flexibility you deserve.'
  }
]