import React from 'react'

import useStyles from '../AboutUs.styles'

export default function Team () {
  const classes = useStyles()
  
  return (
    <div className={classes.teamContainer}>
      <div className='px-5'>
        <h2 className={classes.teamTitle}>Our Team</h2>
        <p className={classes.teamSubtitle}>We are always improving our platform. If you’d like to join a multidisciplinary team dedicated to providing a seamless and extraordinary end-user experience, get in touch. </p>
      </div>
      <div className='d-flex row'>
        <div className='col-12 col-md-6 d-flex align-items-center justify-content-center p-5'>
          <div className='d-flex flex-column px-5'>
            <div className='web-subhead d-flex pb-4'>Join us</div>
            <div className='text-regular text-start pb-2'>
              We’re looking for people to join our team. Send us your resume.
            </div>
            <div className='d-table d-sm-flex justify-content-start pt-5 text-regular'>
              Send resume to:<b className='ps-3'> joinus@multikrd.com</b>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 d-flex justify-content-center align-items-center p-5'>
          <div className='d-flex flex-column w-100'>
            {roles.map(rol => {
              return (
                <div key={rol} className='about-links d-flex justify-content-between'>
                  <div className='about-links-text' style={{ color: '#0097EC' }}>
                    {rol}
                  </div>
                  <div />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const roles = [
  'Operations',
  'Marketing',
  'Engineering & Technology',
  'Sales & partnerships',
  'Finance & Accounting',
  'Research & Development',
  'Customer Service'
]