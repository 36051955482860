import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  teamContainer: {
    backgroundColor: '#004598',
    color: 'white',
    padding: 56,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 32,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 32
    }
  },
  teamSubtitle: {
    padding: 32,
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  teamTitle: {
    marginBottom: 40
  },
  teamTitleContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  }
}), { classNamePrefix: 'AboutUs' })