import React from 'react'

import talent from '../../../assets/images/benefits/talent.webp'
import performance from '../../../assets/images/benefits/performance.webp'
import incentives from '../../../assets/images/benefits/incentives.svg'
import SlideImage from '../../../components/slide-image/SlideImage'
import Footnote from '../../../components/footnote/Footnote'
import AnimatedSparks from '../../../components/animated-sparks/AnimatedSparks'

export default function BenefitsSlides () {
  return (
    <>
      <SlideImage
        title='Attract and retain talent'
        text={<div>
          <p>Employers who offer smart financial tools are more likely to <b>attract and retain talent.</b></p>
          <p>Companies who give employees more savings clout and cash not surprisingly find they earn the <b>loyalty</b> that comes with <b>satisfaction.</b></p>
        </div>}
        image={talent}
        button={false}
      />
      <SlideImage
        title='Attract and retain talent'
        text={<div>
          <p>People who are stressed about making ends meet find their worry impacts every part of life—including productivity at work.</p>
          <p>By increasing your team's financial resilience, you <b>increase focus and engagement.</b></p>
        </div>}
        textPosition='left'
        image={performance}
        button={false}
      />
      <SlideImage
        animation={<AnimatedSparks />}
        title='No-cost, high-impact incentives'
        text={<div>
          <p>MultiKrd allows you to <b>reward</b>, <b>inspire</b>, and <b>empower</b> those you hire at a price you can afford: zero.</p>
          <p>Strengthen company culture by offering personalized discounts and bonuses. Our platform makes it easy to appreciate your hardworking team.</p>
        </div>}
        image={incentives}
        button={false}
      />
      <Footnote notes={['(1) American Psychology Association, “Work and Well-Being Survey”, 2016', '(2) Wagestream, “Earned Wage Access Impact Assessment,” H1 2021']} />
    </>
  )
}