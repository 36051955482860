import React from 'react'

import { AboutUsTeam } from './components'
import Footnote from '../../components/footnote/Footnote'
import RedCardBlock from '../../components/red-card-block/RedCardBlock'
import SlideHeader from '../../components/slide-header/SlideHeader'
import SlideImage from '../../components/slide-image/SlideImage'
import headerImage from '../../assets/images/about-us/header.webp'
import image1 from '../../assets/images/about-us/slide-1.webp'
import image2 from '../../assets/images/about-us/slide-2.webp'
import image3 from '../../assets/images/about-us/slide-3.webp'
import headerShape from '../../assets/images/shapes/orange-bottom-left.svg'

export default function About () {
  return (
    <>
      <SlideHeader
        buttonText='Get in touch'
        figure={headerShape}
        figureClass='figure-header-about'
        image={headerImage}
        text='Employee recognition and reward is central to our platform.'
        textPosition='right'
        title='Multikrd® runs on the power of appreciation.⁽¹⁾'
      />
      <SlideImage
        button={false}
        textPosition='left'
        title='Our Company'
        text={
          <div>
            <p>Multikrd enables companies to attract, reward, and retain an engaged workforce while <b>improving the overall financial well-being</b> of employees. </p>
            <p>Our digital platform integrates a variety of financial tools with the largest promotions network in America. We provide quick and easy employee access to earned wages and earned tips. Our users discover the best online discounts, cashback and savings, as well as our MK easy banking access⁽²⁾. Our service comes at <b>zero cost to employers</b> and is simple to navigate, providing an exceptional user experience. We are a solutions-oriented win-win enterprise.</p>          
          </div>
        }
        image={image1}
      />
      <SlideImage
        button={false}
        title='Mission & vision'
        text={
          <div>
            <p>Our mission is to <b>strengthen workforces by improving employees’ ability to reach financial freedom and resilience.</b></p>
            <p>By implementing innovative thinking, we plan to become the most effective employee engagement and retention solution in the nation. Our clients will be the most competitive in their fields, and their employees the most valued.</p>
          </div>
        }
        image={image2}
      />
      <SlideImage
        button={false}
        textPosition='left'
        title='Our Focus'
        text={<p>We are proud to deliver a smart, sophisticated platform that gives companies an advantage in employee productivity and retention. We strive to <b>give both employers and employees the edge they need to succeed.</b></p>}
        image={image3}
      />
      <Footnote notes={[
          '(1) “Multikrd does not requests any kind of tips or gratuities for their services, all applicable fees are disclosed to you before each transaction under the user Terms & Conditions.',
          '(2) Multikrd LLC is a financial technology company and not a FDIC-insured bank. Checking accounts and the Multikrd Visa® Debit Card are provided by Lewis & Clark Bank, Member FDIC. Funds deposited into the checking account may be eligible for up to $250,000 of FDIC insurance. The FDIC’s deposit insurance coverage only protects against the failure of a FDIC-insured depository institution.'
        ]} />
      <AboutUsTeam />
      <RedCardBlock title='See how MK can help your company' buttonText='Get a demo' />
    </>
  )
}
