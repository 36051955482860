import React from 'react'
import { motion } from 'framer-motion'

import hand from '../../../assets/images/card/hand.webp'
import circle from '../../../assets/images/shapes/blue-circle.svg'
import useStyles from '../Card.styles'

export default function CardFinances () {
  const classes = useStyles()

  return (
    <div className={classes.financesContainer}>
      <div style={{ flex: 1, position: 'relative' }}>
        <motion.img src={circle} className={classes.financesShape} />
        <motion.div className={classes.financesImageContainer}
          initial={{ x: '-100%' }} whileInView={{ x: 0 }} transition={{duration: 0.8}} 
          >
          <img src={hand} className={classes.financesImage} alt='MK Card' />
        </motion.div>
      </div>
      <div className={classes.financesTitleContainer}>
        <h1 className={classes.financesTitle}>Manage Your Finances with Ease</h1>
        <h2 className={classes.financesSubtitle}>The MK Card offers a seamless integration with your earned wage advances and cashback, providing you with quick, stress-free access to your money whenever you need it. Opt in and request your MK Card today!</h2>
      </div>
    </div>
  )
}